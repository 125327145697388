// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';

import {
  Flex,
  Heading,
  PrimaryButton,
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';

import { AppStateProvider, useAppState } from './AppStateProvider';
import { StyledList } from './MeetingDetailsStyled';

const MeetingDetails = () => {
  const { meetingId, toggleTheme, theme } = useAppState();
  const manager = useMeetingManager();

  return (

		<Flex container layout="fill-space-centered">
			<StyledList>
			</StyledList>

		</Flex>
  );
};

export default MeetingDetails;
