import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import './Attendee.css';

export default function Attendee({id, name, index}){
	
	return(

			<Draggable
				key={name}
				draggableId={id}
				index={index}>
				{(provided) => (
					<div id ={name} className= "attendee"
						
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						
						>
						{name}
					</div>
				)}
			</Draggable>
		/*</div>*/
	);
}