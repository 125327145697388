import React, { useState, useCallback, useEffect, useContext } from 'react';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import {SocketContext} from '../socket';
import UploadBox from '../UploadBox/UploadBox'

const Board = ({contentCode, pageNumber, width, height, imgSrc}) => {
  const [canvas, setCanvas] = useState('');
  const socket = useContext(SocketContext)
  const [brushWidth, setBrushWidth]= useState(1)
  const [brushColor, setBrushColor] = useState("#000000")
  const [selectedObject, setSelectedObject] = useState(null)
  const [borderColor, setBorderColor] = useState("#000000")
  const [fillColor, setFillColor] = useState("#000000")
  const [uploadedImageUrl, setUploadedImageUrl] = useState('')
  
  const initCanvas = () =>
     new fabric.Canvas('canv', {
		 width: 1000,
		 height: height*.85,
	   isDrawingMode: true,
     }
	 )

   useEffect(()=> {
	  if(canvas){
		  console.log("next page triggered")
		  canvas.clear()

			fabric.Image.fromURL(uploadedImageUrl, function(myImg) {
			 //i create an extra var for to change some image properties
			 canvas.add(myImg); 
			});
			canvas.renderAll()
		  }
  }, [uploadedImageUrl, canvas])


  useEffect(() => {

		socket.on('newDrawing', data => {
			newDrawingAdd(data,canvas)
		})
	
	return () => {
			socket.off('newDrawing')
		}

	}, [socket, canvas])
	
	useEffect(() => {
	
		console.log('newDraw')
		socket.emit('newDrawing', {'contentCode': contentCode, 'pageNumber': pageNumber})


	}, [])
	
	useEffect(() => {
	
		if(canvas){
			socket.emit('newDrawing', {'contentCode': contentCode, 'pageNumber': pageNumber})
		}

	}, [imgSrc])
	
	 useEffect(() => {
	
	if(canvas){
		socket.on('drawPan', data => {
			drawPan(data,canvas)
		})
	}

	
	return () => {
			socket.off('drawPan')
		}

	}, [socket, canvas])
	
	
  useEffect(() => {
	if(canvas){
		socket.on('drawZoom', data => {
			drawZoom(data,canvas)
		})
	}

	
	return () => {
			socket.off('drawZoom')
		}

	}, [socket, canvas])
	
	useEffect(() => {
			  
		if(canvas){
			socket.on('object-added', data => {
				socketObjectAdd(data, canvas)
			});
		}
		
		return () => {
			socket.off('object-added')
		}
	 }, [socket, canvas]);
	 
	useEffect(() => {
			  
		if(canvas){
		socket.on('new-modification', data => {
				objectModified(data, canvas)
			});
		}
		
		return () => {
			socket.off('object-added')
		}
	 }, [socket, canvas]);
	 
const objectModified = useCallback((data, canvas) => {
	const { obj, id } = data
	console.log(obj, id)
    canvas.getObjects().forEach(object => {
      if (object.id === id) {
        object.set(obj)
        object.setCoords()
        canvas.renderAll()
      }
    })		
})

const drawPan = useCallback((data, canvas) => {
	canvas.setViewportTransform(data)
})

const drawZoom = useCallback((data, canvas) => {
	canvas.zoomToPoint({ x: data.x, y: data.y }, data.zoom)
})

const newDrawingAdd = useCallback((data, canvas) => {
		
		console.log('newDraw', data)
		
		let dataObjects = []
		let dataIds = []
		const scale = (height)/data.size
		
		data.forEach(o => dataObjects.push(o.object))
		data.forEach(o => dataIds.push(o.id))

		  fabric.util.enlivenObjects(dataObjects, function(objects) {
					let c = 0
					
					  objects.forEach(function(o) {
							o.set(o)
							o.set('id', dataIds[c])
							o.setCoords()
							canvas.add(o); 
							
							c=c+1							
					  });
					  
					
					  
			});
			canvas.renderAll(); 
	
})

const socketObjectAdd = useCallback((data,canvas) => {
	  const scale = (height)/data.size
	  
	  fabric.util.enlivenObjects([data.object], function(objects) {
		console.log('sOA called', objects, data)
        objects.forEach(function(o) {
					
			o.set('id', data.id)
														
			canvas.add(o);
				})
		
			
        });	
		canvas.renderAll()
})
  
  useEffect(() => {
    setCanvas(initCanvas())	
  }, []); 
  
  
   useEffect(()=> {
	  if(canvas){
		  console.log("next page triggered")
		  canvas.clear()
			fabric.Image.fromURL(imgSrc, function(myImg) {
			 //i create an extra var for to change some image properties
			 myImg.set('selectable', false)
			 canvas.add(myImg); 
			});
			canvas.renderAll()
		  }
  }, [imgSrc, canvas, uploadedImageUrl])
  
 
  useEffect(
    () => {
	

	
      if (canvas) {
		canvas.on('object:modified', function (options) {
          if (options.target) {
			console.log(options.target)
		const modifiedObj = {
              obj: options.target,
              id: options.target.id,
            }
            socket.emit('object-modified', modifiedObj)
          }
        })
		
		
		
		canvas.on('object:added', function(options) {
			
			console.log(options.target)
			
			if(!("id" in options.target)){
				let id = uuidv4()
				options.target.set('id', id)
				socket.emit('object-added', {'object': options.target, 'size': height,'id': id, 'pageNumber':pageNumber, 'contentCode': contentCode})
				console.log(options.target)

			}

        })

	canvas.renderAll()
	
      }
    },
    [canvas]
  )
  
  
    useEffect(
    () => {
	

	
      if (canvas) {
		
		canvas.on('mousedown', function(o) {
			console.log(o)
			setSelectedObject(o)
        })

	canvas.renderAll()
	
      }
    },
    [canvas]
  )
  
  
  
  useEffect(
    () => {
	

	
      if (canvas) {
		
		canvas.on('mouse:wheel', function(opt) {
		  var delta = opt.e.deltaY;
		  var zoom = canvas.getZoom();
		  zoom *= 0.999 ** delta;
		  if (zoom > 20) zoom = 20;
		  if (zoom < 0.01) zoom = 0.01;
		  canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
		  opt.e.preventDefault();
		  opt.e.stopPropagation();
		  socket.emit('drawZoom', {'x': opt.e.offsetX, 'y': opt.e.offsetY, 'zoom': zoom})
		});
				
		canvas.on('mouse:down', function(opt) {
		  var evt = opt.e;
		  if (evt.altKey === true) {
			this.isDragging = true;
			this.selection = false;
			this.lastPosX = evt.clientX;
			this.lastPosY = evt.clientY;
		  }
		});
		canvas.on('mouse:move', function(opt) {
		  if (this.isDragging) {
			var e = opt.e;
			var vpt = this.viewportTransform;
			vpt[4] += e.clientX - this.lastPosX;
			vpt[5] += e.clientY - this.lastPosY;
			this.requestRenderAll();
			this.lastPosX = e.clientX;
			this.lastPosY = e.clientY;
		  }

		  
		});
		canvas.on('mouse:up', function(opt) {
		  // on mouse up we want to recalculate new interaction
		  // for all objects, so we call setViewportTransform
		  this.setViewportTransform(this.viewportTransform);
		  this.isDragging = false;
		  this.selection = true;
		  socket.emit('drawPan', this.viewportTransform)
		});
     }
    },
    [canvas]
  )
  
function handleToggleDrawingModeClick(){
	canvas.isDrawingMode = !canvas.isDrawingMode
}

function handleBrushWidthChange(event){
	setBrushWidth(parseInt(event.target.value))
	canvas.freeDrawingBrush.width= parseInt(event.target.value,10)
	console.log(canvas.freeDrawingBrush.width)
}

function handleBrushColorChange(event){
	setBrushColor(event.target.value)
	canvas.freeDrawingBrush.color = event.target.value
	
}

function handleFillColorChange(event){
	
	const color = event.target.value
	setFillColor(color)

	function changeObjectFillColor(object, color){
		if (object.fill) {
			object.set("fill", color);
			}

	}

		if (canvas.getActiveObject()) {
			const selectedObject = canvas.getActiveObject();
			
			changeObjectFillColor(selectedObject, color);
			canvas.renderAll();
		}
	
	  
	  if (canvas.getActiveObjects()) {
		const  selectedGroup = canvas.getActiveObjects(); 
		
		  
		for (let i = 0; i < selectedGroup.length; i++) {
			changeObjectFillColor(selectedGroup[i], color);
		}
		canvas.renderAll();
	  }
	  
}


function handleBorderColorChange(event){
	
	const color = event.target.value
	setBorderColor(color)

	function changeObjectBorderColor(object, color){
		if (object.stroke) {
			object.set("stroke", color);
			}
			  
		if (object instanceof fabric.IText) {
			object.setColor(color);
		}
	}

		if (canvas.getActiveObject()) {
			const selectedObject = canvas.getActiveObject();
			
			changeObjectBorderColor(selectedObject, color);
			canvas.renderAll();
		}
	
	  
	  if (canvas.getActiveObjects()) {
		const  selectedGroup = canvas.getActiveObjects(); 
		
		  
		for (let i = 0; i < selectedGroup.length; i++) {
			changeObjectBorderColor(selectedGroup[i], color);
		}
		canvas.renderAll();
	  }
	
}

function handleAddTextClick(event){
// Create a new Text instance
        const text = new fabric.Textbox('Enter Text Here', {
            fill: fillColor,
			height: 10
        });
 
        // Render the Text on Canvas
        canvas.add(text);
		canvas.renderAll();
		canvas.isDrawingMode = false
	
}

function handleAddCheckmarkClick(){
	let checkmark = new fabric.Path("M4.67,67.27c-14.45-15.53,7.77-38.7,23.81-24C34.13,48.4,42.32,55.9,48,61L93.69,5.3c15.33-15.86,39.53,7.42,24.4,23.36L61.14,96.29a17,17,0,0,1-12.31,5.31h-.2a16.24,16.24,0,0,1-11-4.26c-9.49-8.8-23.09-21.71-32.91-30v0Z");  
	checkmark.set('fill',fillColor)
	checkmark.set('stroke', borderColor)
	checkmark.set('scaleX', .2)
	checkmark.set('scaleY', .2)
	canvas.add(checkmark)
	canvas.renderAll()
	canvas.isDrawingMode = false
}

function handleAddXClick(){
	let x = new fabric.Path("M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z")
	x.set('fill',fillColor)
	x.set('stroke', borderColor)
	x.set('scaleX', .05)
	x.set('scaleY', .05)
	canvas.add(x)
	canvas.renderAll()
	canvas.isDrawingMode = false

}

function handleAddStarClick(){

	function starPolygonPoints(spikeCount, outerRadius, innerRadius) {
	  const rot = Math.PI / 2 * 3;
	  const cx = outerRadius;
	  const cy = outerRadius;
	  const sweep = Math.PI / spikeCount;
	  const points = [];
	  let angle = 0;

	  for (let i = 0; i < spikeCount; i++) {
		let x = cx + Math.cos(angle) * outerRadius;
		let y = cy + Math.sin(angle) * outerRadius;
		points.push({x: x, y: y});
		angle += sweep;

		x = cx + Math.cos(angle) * innerRadius;
		y = cy + Math.sin(angle) * innerRadius;
		points.push({x: x, y: y});
		angle += sweep
	  }
	  return (points);
	}

	const points=starPolygonPoints(5,10,5);
	let myStar = new fabric.Polygon(points, {
	  stroke: borderColor,
	  left: 100,
	  top: 10,
	  fill: fillColor,
	  strokeWidth: 2,
	  strokeLineJoin: 'bevil'
	},false);
	
	canvas.add(myStar);
	canvas.renderAll();
	canvas.isDrawingMode = false
}

function handleObjectDeleteClick(){
const activeObject = canvas.getActiveObject(),
    activeGroup = canvas.getActiveObjects();
    if (activeObject) {
        canvas.remove(activeObject);
    }else if (activeGroup) {
        activeGroup.forEach(function(object) {
            canvas.remove(object);
        });

    }
}

function handleUploadedUrlImageUpdate(url){
	setUploadedImageUrl(url)
}


 return (
	<div id="drawHolder">
		<div style={{"border-bottom-style":"inset", backgroundColor: "rgb(105,105,105)"}} id="canvasHolder">
			<canvas id="canv" />
		</div>
		<div id="drawButtonHolder">
			<button onClick={()=>handleToggleDrawingModeClick()}>Toggle Drawing Mode</button>
			<button onClick={()=>handleAddStarClick()}> Add Star</button>
			<button onClick={()=>handleAddTextClick()}> Add Text</button>
			<button onClick={()=>handleAddCheckmarkClick()}> Add Checkmark</button>
			<button onClick={()=>handleAddXClick()}> Add X Mark</button>
			<button onClick={()=>handleObjectDeleteClick()}> Delete</button>
			<label>Brush Width
				<input type="range" onChange={handleBrushWidthChange} id ="brushWidth" min="1" max="60" value={brushWidth}/>
			</label>
			<div id="colorSelections">
			
				<label>Brush Color
					<input type="color" onChange={handleBrushColorChange} id ="brushColor" value={brushColor}/>
				</label>
				
				<label>Border Color
					<input type="color" onChange={handleBorderColorChange} id ="brushColor" value={borderColor}/>
				</label>
				
				<label>Fill Color
					<input type="color" onChange={handleFillColorChange} id ="brushColor" value={fillColor}/>
				</label>
			<UploadBox
				contentCode = "fabric"
				height = "100px"
				width = "100px"
				onUploadedUrlImageUpdate = {handleUploadedUrlImageUpdate}
			/>

			</div>
		</div>
	</div>
  );
 
};

export default Board;
