const initialData = {
  messages: {
    'message-1': { id: 'message-1', type: "text",  content: 'The truth about the molemen is mind-rending. Those who discover it and understand it are sucked out of existence. Thankfully, I am too stupid.' },
    'message-2': { id: 'message-2', type: "text", content: 'Teach the world to laugh again' },
    'message-3': { id: 'message-3', type: "text", content: 'My skunk forgot his lunchbox' },
    'message-4': { id: 'message-4', type: "text", content: 'Forge ahead and relinquish all your dishwashing duties' },
	'message-5': { id: 'message-5', type: "image", content: 'https://docs.perspectives.icearp.net:8080/files/PDF%20Document.png' },
	'message-6': { id: 'message-6', type: "image", content: 'https://docs.perspectives.icearp.net:8080/files/ImageSort.png' }
  },
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Keep',
      messageIds: ['message-1', 'message-2', 'message-3', 'message-4', 'message-5', 'message-6'],
    },
    'column-2': {
      id: 'column-2',
      title: 'Neutral',
      messageIds: [],
    },
    'column-3': {
      id: 'column-3',
      title: 'Discard',
      messageIds: [],
    },
  },
    "fullColumnList": ['column-1', 'column-2', 'column-3'] 
};

export default initialData;
