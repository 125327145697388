import React from 'react';
import './Answer.css';

export default function Answer({answer, type, response, onValueChange}){

	return(
	<>
		{type==="MC" &&
			<>
				{(response.includes(answer)) ?
					<div id="clicked" onClick={()=>onValueChange(answer)}> {answer}</div> :
					<div id="pollOption" onClick={()=>onValueChange(answer)}> {answer}</div>
				}
		</>
		}
		
		{type==="MA" &&
			<>
				{(response.includes(answer)) ?
					<div id="clicked" onClick={()=>onValueChange(answer)}> {answer}</div> :
					<div id="pollOption" onClick={()=>onValueChange(answer)}> {answer}</div>
				}
		</>
		}
	</>
	)
}