import React,{useState, useEffect, useContext, useCallback, useRef} from 'react';
import StimuliCard from '../StimuliCard/StimuliCard';
import ContentDisplayHandler from '../ContentDisplayHandler/ContentDisplayHandler';
import {SocketContext} from '../socket';
import layoutsConfig from './layouts.js'
import contents from './content.js'
import './WidthManager.css'
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import TopMenu from '../TopMenu/TopMenu';
import { v4 as uuidv4 } from 'uuid';
import initialData from './initData.js';


function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

export default function WidthManager({userRole}){
	
	const socket = useContext(SocketContext)
	    
	const [currentLayout, setCurrentLayout] = useState('axzjlasdu')	
	const [content, setContent] = useState(contents)
	const [layouts, setLayouts] = useState(layoutsConfig)
	const [dragObject, setDragObject] = useState(initialData)
	const [screenChangeToggle, setScreenChangeToggle] = useState(false)

	const layoutRef = useRef(layoutsConfig)
	const contentRef = useRef(contents)
	const DisplayRef = useRef()
	const dragRef = useRef(initialData)
	
	const [copyLayoutMode, setCopyLayoutMode] = useState(false)
	const [newLayoutPopUp, setNewLayoutPopUp] = useState(false)
	
	const [height, setHeight] = useState(null);
	const [width, setWidth] = useState(null);

	
	
useEffect(() => {
	  	  
    socket.on("dragKey", (data) => {
		updateDragKey(data)
		
			});
			
	return () => {
		socket.off("dragKey", updateDragKey)
	}
	
 }, [socket]);
 
 
 useEffect(() => {
	  	  
    socket.on("drag", (data) => {
		updateDragObject(data)
		
			});
			
	return () => {
		socket.off("drag", updateDragObject)
	}
	
 }, [socket]);
  
	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {

			setHeight(DisplayRef.current.clientHeight);
			setWidth(DisplayRef.current.clientWidth);
		}, 10)
		window.addEventListener('resize', debouncedHandleResize)


		return _ => {
		  window.removeEventListener('resize', debouncedHandleResize)
		
	}
	  }, [layouts, userRole])
	  
	useEffect(() => {
		console.log(DisplayRef.current.clientHeight)
		console.log(DisplayRef.current.clientWidth)
		console.log(layouts)
		setHeight(DisplayRef.current.clientHeight);
		setWidth(DisplayRef.current.clientWidth);

	}, [userRole])
		  
	useEffect(() => {
	  	  
    socket.on("lcsNewUser", (data) => {
			///lcs = layout, content, layouts
			lcsNewUser(data)
			});	
	return () => {
			socket.off("lcsNewUser", lcsNewUser)
		}
 
 }, [socket]);

//retrieve newContent
	useEffect(() => {
			  
		socket.on("newContent", (data) => {
			newContent(data)
		});
		
		return () => {
			socket.off("newContent", newContent)
		}
	 }, [socket]);
	 
	 
//retrieve lcs changes 
	useEffect(() => {
			  
		socket.on("layoutsUpdate", (data) => {
			layoutsUpdate(data)
			console.log(data)
		
		});
		
		return () => {
			socket.off("layoutsUpdate", layoutsUpdate)
		}
	 }, [socket]);


//retrieve layout changes 
	useEffect(() => {
			  
		socket.on("layout", (data) => {
			layoutChange(data)
		});
		
		return () => {
			socket.off("layout", layoutChange)
		}
	 }, [socket]);

 
function updateDragObject(data){
	dragRef.current = data
	console.log(data)
	setDragObject({...dragRef.current})
 }

function updateDragKey(data){
	
	console.log(data)
	
	if(data.subGroup){
		dragRef.current[data.subGroup][data.key] = data.object
	} else {
		dragRef.current[data.key] = data.object
	}
	
	console.log({...dragRef.current})
	
	setDragObject({...dragRef.current})	
}

	const lcsNewUser = useCallback((data) => {
		dragRef.current = {...data.dragObject}
		layoutRef.current = {...data.layouts.layouts}
		contentRef.current = {...data.content.content}
		setDragObject({...dragRef.current})
		setContent({...data.content.content})
		setLayouts({...data.layouts.layouts})
		setCurrentLayout(data.currentLayout)

		
	})

	const newContent = useCallback((data)=>{
		contentRef.current.content[data.contentCode] = data.newContent	
		setContent({...contentRef.current})	
		
		
		//layouts need to be set after new content. Doing it locally when the new content is emitted will fail since it needs to receive the new content from the emit before the content can be added to the layout. Thus I am doing it here. 
		setLayouts({...layoutRef.current})
		console.log(contentRef.current)
	});
	
	const layoutsUpdate = useCallback((data)=>{
		
		if((!layoutRef.current.Layouts.layoutOrder.includes(data.layoutId)) && data.layoutId !== "layoutOrder"){
			layoutRef.current.Layouts.layoutOrder.push(data.layoutId)
		}
		
		layoutRef.current.Layouts[data.layoutId] = data.layout
		
		console.log({...layoutRef.current})
		
		setLayouts({...layoutRef.current})
		
		
		
	})

	function updatePosition(nx,ny, contentCode){
		console.log("x", nx, "y", ny, contentCode)
		layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.x = Number(nx/width)
		layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.y = Number(ny/height)
		setLayouts({...layoutRef.current})
		socket.emit("layoutsUpdate", {"layoutId": currentLayout, "layout": layoutRef.current.Layouts[currentLayout]})
		
	}

	function handleResizeStop(w, h, contentCode, position){
		console.log("hrs", Number(h.substring(0, h.length - 2))/height)
		
		layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.width = Number(w.substring(0, w.length - 2))/width
		layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.height = Number(h.substring(0, h.length - 2))/height
		layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.x = position.x/width
		layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.y = position.y/height
		
		console.log(currentLayout, layoutRef.current.Layouts[currentLayout])
		setLayouts({...layoutRef.current})
		socket.emit("layoutsUpdate", {"layoutId": currentLayout, "layout": layoutRef.current.Layouts[currentLayout] })
	}

	function updateWidthHeight(w,h, contentCode){

			console.log("U", "w", width, Number(w.substring(0, w.length - 2)), Number(w.substring(0, w.length - 2))/width,  "h", height, Number(h.substring(0, h.length - 2)), Number(h.substring(0, h.length - 2))/height)
			layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.width = Number(w.substring(0, w.length - 2))/width
			layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.height = Number(h.substring(0, h.length - 2))/height
			setLayouts({...layoutRef.current})
	}


	
	const layoutChange = useCallback((layoutId)=>{
		setCurrentLayout(layoutId)
	})
	

	const lcsChange = useCallback((data) => {
		lcsChange(data)
	})
	
	const onDragEnd = (result) => {
		const {destination, source, draggableId } = result 
		 
		 if (!result.destination) {
			return null;
		 } 
		if (
		  destination.droppableId === source.droppableId &&
		  destination.index === source.index
		) {
		  return;
		}
				
		const newArray = layoutRef.current.Layouts.layoutOrder.filter(lindex => lindex !== layoutRef.current.Layouts.layoutOrder[source.index])
		layoutRef.current.Layouts.layoutOrder = [...newArray.slice(0, destination.index), layoutRef.current.Layouts.layoutOrder[source.index], ...newArray.slice(destination.index)]


		//setLayouts({...layoutRef.current})
		socket.emit("layoutsUpdate", {"layoutId": "layoutOrder", "layout": [...layoutRef.current.Layouts.layoutOrder]})
		
    }
	
	function handleLayoutDeleteClick(layoutId){
		layoutRef.current.Layouts.layoutOrder = layoutRef.current.Layouts.layoutOrder.filter(id => id!==layoutId)
		socket.emit("layoutsUpdate", {"layoutId":"layoutOrder", "layout": layoutRef.current.Layouts.layoutOrder})
		console.log(layoutId, layoutRef.current)
		setLayouts({...layoutRef.current})
	}
	
	function handleContentRemoveClick(contentCode){
		layoutRef.current.Layouts[currentLayout].list = layoutRef.current.Layouts[currentLayout].list.filter(contentId => contentCode!== contentId)
		socket.emit("layoutsUpdate", {"layoutId": currentLayout, "layout": layoutRef.current.Layouts[currentLayout]})
		setLayouts({...layoutRef.current})
	}
	
	function handleLayoutClick(layoutId){
		
		if(copyLayoutMode){
			copyLayout(layoutId)
			setCopyLayoutMode(false)
			setNewLayoutPopUp(false)
		}else if(layoutId!==currentLayout){
			socket.emit("layout", layoutId)
		}

	};
	
	function copyLayout(layoutId){
		const newLayoutId = uuidv4()		
		layoutRef.current.Layouts[newLayoutId] = {...layoutRef.current.Layouts[layoutId]}
		layoutRef.current.Layouts[newLayoutId].key = newLayoutId
		layoutRef.current.Layouts.layoutOrder.push(newLayoutId)
		socket.emit("layoutsUpdate", {"layoutId": newLayoutId, "layout": layoutRef.current.Layouts[newLayoutId]})
		setLayouts({...layoutRef.current})
	}
	
	function cancelCopyLayoutMode(){
		setNewLayoutPopUp(false)
		setCopyLayoutMode(false)
	}
	
	function handleNewLayoutClick(){

		const newLayoutId = uuidv4()

		
		layoutRef.current.Layouts[newLayoutId] = {"list":[], "title": "New Layout", "thumbnail": "https://docs.perspectives.icearp.net:8080/files/Blue.PNG", "key": newLayoutId, "position": {}}
		layoutRef.current.Layouts.layoutOrder.push(newLayoutId)
		socket.emit("layoutsUpdate", {"layoutId": newLayoutId, "layout": layoutRef.current.Layouts[newLayoutId]})
		setLayouts({...layoutRef.current})
		setNewLayoutPopUp(false)
	
	}
	
	function handleDuplicateContentClick(contentCode){
		const newContentCode=uuidv4()
		let newContent = contentRef.current.content[contentCode]
		newContent.key= newContentCode
		socket.emit("newContent", {"contentCode": newContentCode, "newContent": newContent })	
		socket.emit("newContent", {"contentCode": "contentList", "newContent": [...contentRef.current.content.contentList, newContentCode ] })	
		
		
		if(!layoutRef.current.Layouts[currentLayout].list.includes(newContentCode)){
			const newLayout = {...layoutRef.current.Layouts[currentLayout]}
			newLayout.list.push(newContentCode)
			console.log({...layoutRef.current})
			newLayout.position[newContentCode] = {"coordinates":{"x": 0, "y":0, "height":.25, "width": .25}}
			socket.emit("layoutsUpdate", {"layoutId": currentLayout, "layout": newLayout})
		}
		
	}
	
	function handleAddContentClick(type){
		
		function getNewContentCode(type){
			if(type==="camera"){
				return("camera")
			}else{
				return(uuidv4())
			}
		}
				
		const newContentCode = getNewContentCode(type)
		
		if(type==="empty"){
			const newContent = {"type": type, "room": "perspectives", "title": "New Display", "key": newContentCode}
			socket.emit("newContent", {"contentCode": newContentCode, "newContent": newContent })	
			socket.emit("newContent", {"contentCode": "contentList", "newContent": [...contentRef.current.content.contentList, newContentCode ] })	
		} else if(type==="DnD" ){
			console.log(layouts, content)
			const newContent = {"type": type, "room": "perspectives", "title": "Message Sort", "key": newContentCode, "columnOrder": []}
			socket.emit("newContent", {"contentCode": newContentCode, "newContent": newContent })	
			socket.emit("newContent", {"contentCode": "contentList", "newContent": [...contentRef.current.content.contentList, newContentCode ] })	
		} else if(type==="poll" ){
			console.log(layouts, content)
			const newContent = {"type": "MC", "room": "perspectives", "title": "Poll", "key": newContentCode, "question": "", "answers":[], "responses":[]}
			socket.emit("newContent", {"contentCode": newContentCode, "newContent": newContent })	
			socket.emit("newContent", {"contentCode": "contentList", "newContent": [...contentRef.current.content.contentList, newContentCode ] })	
		}
		
		if(!layoutRef.current.Layouts[currentLayout].list.includes(newContentCode)){
			const newLayout = {...layoutRef.current.Layouts[currentLayout]}
			newLayout.list.push(newContentCode)
			console.log({...layoutRef.current})
			newLayout.position[newContentCode] = {"coordinates":{"x": 0, "y":0, "height":.25, "width": .25}}
			socket.emit("layoutsUpdate", {"layoutId": currentLayout, "layout": newLayout})
		}

	}
			
	
	function handleNextClick(contentCode){
		const newContent = contentRef.current.content[contentCode]
		newContent.urlIndex = newContent.urlIndex+1
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent})
	}
	
	function handlePreviousClick(contentCode){
		const newContent = contentRef.current.content[contentCode]
		newContent.urlIndex = newContent.urlIndex-1
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent})
	}
	
	function getLocalHeight(contentCode){
		return (height*layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.height) + "px"
	}
	
	function getLocalX(contentCode){
		console.log( contentCode, layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.x, width, width*layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.x )
		return (width*layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.x)
	}
	
	function getLocalY(contentCode){
		console.log(contentCode,layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.y, height, height*layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.y)
		return(height*layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.y)
	}
	
	function getLocalWidth(contentCode){
		return (width*layoutRef.current.Layouts[currentLayout].position[contentCode].coordinates.width) + "px"
	}
		
	function getDisplayWidth(){
		if(userRole===3){
			return "77vw"
		}else if(userRole===2){
			return "84vw"
		} else if(userRole===1){
			return "98vw"
		}
		
	}
		
	function getDisplayHeight(){
		
		if(userRole===2){
			return "95vh"
		} else{
			return "86vh"
		}
				
	}
	
	function getMeetingWidth(){
		if(userRole===3){
			return "84vw"
		}else if(userRole===2){
			return "84vw"
		} else if(userRole===1){
			return "98vw"
		}
		
	}
	
	return(

		<div className="meetingContainer" style= {{"display": "inline-block","width":getMeetingWidth(), "height": "97vh", "padding":"5px"}}>

			<>
			{userRole!==2 && <TopMenu content={content.content} onAddContentClick={handleAddContentClick} onDuplicateContentClick = {handleDuplicateContentClick} userRole={userRole} />}
			
			<div id= "displayAndLayoutBar" style={{"display":"flex", "flex-direction":"row"}}>
				<div id="ContentDisplay" ref = {DisplayRef} style={{"height": getDisplayHeight(), "background-color":"#696969", "width":getDisplayWidth(), "display": "inline-block", "border-style":"inset", "margin":"5px"}}>

					<ContentDisplayHandler 
						getLocalWidth= {getLocalWidth}
						getLocalHeight = {getLocalHeight}
						getLocalX= {getLocalX}
						getLocalY = {getLocalY}
						content = {content}
						layouts = {layouts}
						userRole = {userRole}
						updateWidthHeight = {updateWidthHeight}
						updatePosition = {updatePosition}
						currentLayout = {currentLayout}
						onPreviousClick = {handlePreviousClick}
						onNextClick = {handleNextClick}
						onContentRemoveClick = {handleContentRemoveClick}
						handleResizeStop = {handleResizeStop}
						dragObject = {dragObject}
					/>								

				</div>	


						<DragDropContext onDragEnd={onDragEnd}>	
							{userRole===3&&
							
									<>					

								<div id="LayoutBarHolder" style={{"margin":"5px","display": "inline-block", "text-align": "center", "background-color":"#696969", "height": "86vh", "width": "5vw", "vertical-align":"top","border-style":"inset"}}>
									<Droppable droppableId="droppable">
									
										
										{(provided) => (
											<div id="LayoutBar" ref={provided.innerRef}
											{...provided.droppableProps}>
												{layouts.Layouts.layoutOrder.map((layoutName,index) =>
													<StimuliCard 
														key={layouts.Layouts[layoutName].key}
														cardKey= {layouts.Layouts[layoutName].key}
														title={layouts.Layouts[layoutName].title}
														index={index}
														currentLayout ={currentLayout}
														layoutId={layoutName}
														copyLayoutMode={copyLayoutMode}
														thumbnail = {layouts.Layouts[layoutName].thumbnail}
														onLayoutClick={handleLayoutClick}
														onLayoutDeleteClick = {handleLayoutDeleteClick}
													/>)}
														{provided.placeholder}
																					
											</div>)}
									</Droppable>
								
										<div id="NewLayoutButtonHolder">
											{newLayoutPopUp &&
												<>
													{!copyLayoutMode && <button onClick={()=>handleNewLayoutClick()}>  New  </button>}
													{copyLayoutMode ? <><button onClick={()=>cancelCopyLayoutMode()}>Cancel</button> <p style={{"background-color":"yellow"}}>Click on Layout to Copy</p> </> : <button onClick={()=>setCopyLayoutMode(true)}> Copy </button> }
												</>}
															
														
											<img src ="https://www.computerhope.com/jargon/p/plus.png" onClick={()=> setNewLayoutPopUp(true)} style={{"cursor": "pointer", "horizontal-align": "middle", "height": "20px", "width": "20px", "display": "inline-block", "margin": "10px"}}/>	
										</div>

								</div>


							
							</>
							}				
						</DragDropContext>	
			</div>
	
			</>

		</div>
	
	);
}