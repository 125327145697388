import Chat from "../Chat/chat";
import Home from "../Home/home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import React, {useState} from "react";
import WidthManager from '../WidthManager/WidthManager'
import AttendeeList from '../AttendeeList/AttendeeList'
import {SocketContext, socket} from '../socket';
import userDataImport from './userData.js';

function Appmain(props) {

const [userRole, setUserRole] = useState(3)
const [userData, setUserData] = useState(userDataImport)

function handleRoleChange(newValue) {
	setUserRole(newValue)
}

function buildUserData(users, roles, roleOrder){
	const newUserData = {users, roles, roleOrder}
	setUserData(newUserData)	
}

function isParticipant(){
	if(userRole>1){
		return false
	} else {
		return true
	}
}

  return (
  <SocketContext.Provider value={socket}>
		<div id="App" style = {{"display": "flex", "flex-direction":"row"}}>    
			
			<WidthManager userRole={userRole}/>
			
			<div className={isParticipant()? 'hidden' : 'show'}>
				<div id= "VirtualBackRoom" style={{"background-color": "#696969","border-style": "inset","margin": "10px", "padding": "5px", "height": "94vh", "display": "inline-block","overflow-y":"auto", "overflow-x":"hidden"}}>
					<AttendeeList userRole={userRole} onRoleChange = {handleRoleChange} userData = {userData}  buildUserData= {buildUserData} />
					<Chat
					  userRole={userRole}
					  id={props.match.params.Id}
					  room= "perspectives"
					  userData={userData}
					  
					/>
				</div>
			</div>
		</div>
	</SocketContext.Provider>
  );
}
function App() {
  return (
    <SocketContext.Provider value={socket}>
		<Router>
		  <div className="App">
			<Switch>
			  <Route path="/" exact>
				<Home />
			  </Route>
			  <Route path="/perspectives/:Id" component={Appmain} />
			</Switch>
		  </div>
		</Router>
	</SocketContext.Provider>
  );
}

export default App;


