import React, {useState, useEffect, useContext} from 'react';
import Answer from './Answer.js'
import ResponseView from './ResponseView.js'
import {SocketContext} from '../socket';
import './poll.css';

export default function MultipleChoicePoll({contentCode, room, userRole, question, answers, width, type, height}){
	
	const socket = useContext(SocketContext)
	const [response, setResponse] = useState("")
	const [view, setView] = useState("question")
	const [pollQuestion, setPollQuestion] = useState(question)
	const [pollAnswers, setPollAnswers] = useState(answers)
	const [pollType, setPollType] = useState(type)
	const [editMode, setEditMode] = useState(false)
	const [rView, setRView] = useState(null)

//retrieve responses 
	useEffect(() => {
			  
		socket.on("pollResponse", (data) => {
			setRView(data)
			console.log(data)
		});
		
		return () => {
			socket.off("pollResponse")
		}
	 }, [socket]);

	function handleResponseSubmitClick(){
		console.log({"contentCode": contentCode, "response": {"type": type, "question": question, "answers": answers, "response": response, "timeStamp": Date.now() }})
		socket.emit("pollResponse", {"contentCode": contentCode, "response": {"type": type, "question": question, "answers": answers, "response": response, "timeStamp": Date.now() }})	
	}
	
	function handleResponseValueChange(answer){
		setResponse([answer])
	}
	
		
	function handleQuestionChange(event){
		setPollQuestion(event.target.value);
	}
	
	function handleAnswerChange(event){
		setPollAnswers(event.target.value.split('\n'))
	}
	
	function handleTitleQuestionAnswerUpdate(){
		const newContent = {"type": pollType, "room": "perspectives", "title":"Poll", "key": contentCode, "question": pollQuestion, "answers": pollAnswers}
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent })	
		setEditMode(!editMode)
		setResponse("")
	}
	
	function handleTypeSelect(e){
		setPollType(e.target.value)
	}
	
	function handleTitleQuestionAnswerCancel(){
		setPollQuestion(question)
		setPollAnswers(answers)
		setEditMode(!editMode)

	}
	
	function handleViewResultsClick(){
		setView("results")
		
		if(rView===null){
			socket.emit("pollRequest", contentCode)	
	}
	
}
	
	return(
	<>
		{(userRole>1 && !editMode && view==="question") && <button className="btn-1" style={{"float":"right", "margin":"5px"}} onClick={()=>handleViewResultsClick()}> View Results </button>}
		{(userRole>1 && view==="results") && <button className="btn-1" style={{"float":"right", "margin":"5px"}} onClick={()=>setView("question")}> View Question </button>}
		<div id="poll" style={{"display":"flex", "flex-direction":"column", "align-items":"stretch", "width": width, "height": height, "margin":"auto", "border-style":"inset", "overflow":"auto", "background-color":"#BDBDBD"}}>
			
			{editMode &&
				<>
					 <select 
						value={pollType} 
						onChange={handleTypeSelect} 
					  >
					   <option value="MC">Multiple Choice</option>
						<option value="MA">Multiple Answer</option>
						<option value="OE">Open Ended</option>
						<option value="DD">Ranking Exercise</option>
					</select>
				</>
			}

			{view==="question" &&
			<>

				{editMode ? 
				<>
				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					<input onChange={handleQuestionChange} placeholder="Type question here" style={{"width":"90%", "background-color":"white", "padding":"2px", "font-weight":"400", "text-align":"left"}}type="text" value ={pollQuestion}/> 
				</div>
				</>
				:
				<>
				

				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					{question}
				</div>
				</>}
				{editMode ?
				<>
				{pollType!=="OE" && 
				<>
				<div id="poll-answers">
					<textarea id="poll-answers-text-area" placeholder="Please enter one answer per line" onChange= {handleAnswerChange} name="poll-answers" rows="10" cols="50">
						{answers.join('\n')}
					</textarea>
				</div>
				</>}
					<button className="btn-1" onClick={()=>handleTitleQuestionAnswerUpdate()}>Update</button>
					<button className="btn-1" onClick={()=>handleTitleQuestionAnswerCancel()}>Cancel</button> 
				</>
				:
				<div style={{"display":"flex","flex-direction": "column","justify-content":"center","height":"85%"}}id="poll-answers">

						{answers.map((answer, index) =>
							<Answer
								response = {response}
								answer = {answer}
								key = {index}
								type = {type}
								onValueChange = {handleResponseValueChange}
							/>
						)}
				</div>}

			</>
			
			}
			
			{view==="results" &&
			<>
				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					{question}
				</div>
			<ResponseView
				type= {type}
				rView = {rView}
			/>
				
			</>
			}
		</div>
			{(!editMode && view==="question" ) &&
			<div className = "buttonHolder" style={{"margin-left":"10px","display":"block"}}>
			{userRole===3 && <button className="btn-1" style={{"float":"bottom", "border-style":"outset"}} onClick={()=> setEditMode(!editMode)}> Edit </button>}
			<button style={{"float":"bottom", "border-style":"outset"}} className="btn-1" onClick={()=>handleResponseSubmitClick()}> Submit</button>
			</div>
			}
	
	</>
	
	)
}