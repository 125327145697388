import React from "react";

function Chatter({index, name, id}) {
	
  return (
		<div 
		 id="chatContainer"
		 style={{ "display":"inline", "background-color":"#D3D3D3"}}
		 >
			
			<div 
				style={{"padding":"1px", "color":"black", "margin":"1px", "border-style": "inset", "width":"75%", "cursor":"pointer", "background-color":"#D3D3D3"}}
				id="chatter"
				>
					{name}
			</div>			
			
		</div>
		
  );
}

export default Chatter;