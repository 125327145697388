import React from "react";
import "./EditTitle.css";

export default function EditTitle({title}) {

  const [toggle, setToggle] = React.useState(true);
  const [text, setText] = React.useState(title);

  function toggleInput() {
    setToggle(false);
  }

  function handleChange(event) {
    setText(event.target.value);
  }

  return (
    <div className="EditTitle">
      {toggle ? (
        <div id = "oDC" onDoubleClick={toggleInput}>{text}</div>
      ) : (
        <input type="text" value={text} onChange={handleChange} />
      )}
    </div>
  );
}

