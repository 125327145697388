import React, {useState, useEffect, useContext} from 'react';
import {SocketContext} from '../socket';
import ResponseView from './ResponseView.js'

export default function OpenEndedPoll({contentCode, room, userRole, answers, question, width, type, height}){
	
	const socket = useContext(SocketContext)
	const [response, setResponse] = useState("")
	const [view, setView] = useState("question")
	const [pollQuestion, setPollQuestion] = useState(question)
	const [pollType, setPollType] = useState(type)
	const [pollAnswers, setPollAnswers] = useState(answers)
	const [editMode, setEditMode] = useState()
	const [rView, setRView] = useState(null)

//retrieve responses 
	useEffect(() => {
			  
		socket.on("pollResponse", (data) => {
			setRView(data)
			console.log(data)
		});
		
		return () => {
			socket.off("pollResponse")
		}
	 }, [socket]);
	 
	function handleResponseSubmitClick(){
		console.log({"contentCode": contentCode, "response": {"type": type, "question": question, "response": response, "timeStamp": Date.now() }})
		socket.emit("pollResponse", {"contentCode": contentCode, "response": {"type": type, "question": question, "answers": answers, "response": response, "timeStamp": Date.now() }})	
	}
	
	function handleResponseValueChange(event){
		setResponse([event.target.value])
	}
	
	function handleAnswerChange(event){
		setPollAnswers(event.target.value.split('\n'))
	}
	
	function handleQuestionChange(event){
		setPollQuestion(event.target.value);
	}
	
	function handleTitleQuestionAnswerUpdate(){
		const newContent = {"type": pollType, "room": "perspectives", "title":"Poll", "key": contentCode, "question": pollQuestion, "answers": answers}
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent })	
		setEditMode(!editMode)
		setResponse("")
	}
	
	function handleTypeSelect(e){
		setPollType(e.target.value)
	}
	
	function handleTitleQuestionAnswerCancel(){
		setPollQuestion(question)
		setEditMode(!editMode)
	}
	
	return(
		<>
		
		{(userRole>1 && !editMode && view==="question") && <button style={{"float":"right", "margin":"5px"}} onClick={()=>setView("results")}> View Results </button>}
		{(userRole>1 && view==="results") && <button style={{"float":"right", "margin":"5px"}} onClick={()=>setView("question")}> View Question </button>}
		
		<div id="poll" style={{"display":"flex", "flex-direction":"column", "align-items":"stretch", "width": width, "height": height, "margin":"auto", "border-style":"inset", "overflow":"auto", "background-color":"#BDBDBD"}}>

			{editMode &&
				<>
					 <select 
						value={pollType} 
						onChange={handleTypeSelect} 
					  >
					   <option value="MC">Multiple Choice</option>
						<option value="MA">Multiple Answer</option>
						<option value="OE">Open Ended</option>
						<option value="DD">Ranking Exercise</option>
					</select>
				</>
			}
				
			
			
			{view==="question" &&
			<>

				{editMode ? 
				<>
				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					<input onChange={handleQuestionChange} style={{"width":"90%", "background-color":"white", "padding":"2px", "font-weight":"400", "text-align":"left"}}type="text" placeholder="Type Question Here" value={pollQuestion}/> 
				</div>
				</>
				:
				<>
				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					{question}
				</div>
				</>}
			
			{editMode ? 
				<>
						{pollType!=="OE" && 
						<>
						<div id="poll-answers">
							<textarea id="poll-answers-text-area" placeholder="Please enter one answer per line" onChange= {handleAnswerChange} name="poll-answers" rows="10" cols="50">
								{answers.join('\n')}
							</textarea>
						</div>
						</>}
				
					<button className= "button" onClick={()=>handleTitleQuestionAnswerUpdate()}>Update</button>
					<button className= "button" onClick={()=>handleTitleQuestionAnswerCancel()}>Cancel</button> 
				</>
				:
				<>
					<input style={{"width":"90%","margin":"10px", "font-size":"20px",  "background-color":"white", "padding":"2px", "font-weight":"400", "text-align":"left"}} placeholder="Type your response here" type="text" onChange={handleResponseValueChange}/>
				</>}
				</>
			}
			


			
			
			{view==="results" &&
			<>
				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					{question}
				</div>	
				
			<ResponseView
				type= {type}
				rView = {rView}
			/>
				
				
			</>
			}


		</div>
		
			{(!editMode && view==="question" ) &&
			<>{userRole===3 && <button className="button" style={{"float":"bottom"}} onClick={()=> setEditMode(!editMode)}> Edit </button>}
			<button className="button" style={{"float":"bottom"}} onClick={()=>handleResponseSubmitClick()}> Submit</button>
			</>
			}
		</>
	
	)
}