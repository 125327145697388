import React, {useState, useContext, useRef, useEffect} from 'react';
import {DragDropContext} from 'react-beautiful-dnd';

import Column from './Column';
import styled from 'styled-components';
import {SocketContext} from '../socket';
import { v4 as uuidv4 } from 'uuid';
import ColumnList from './ColumnList'

export default function DnD({dragObject, contentCode, columnOrder, height, width}){
	
	const socket = useContext(SocketContext)
	const [showColumnList, setShowColumnList] = useState(false)
	const [addMode, setAddMode] = useState(false)
	const [deleteMode, setDeleteMode] = useState(false)

	const Container = styled.div`
	  display: flex;
	  height: ${height};
	  width: ${width};
	  align-items: stretch;
	  overflow-x: scroll;
	`;


function handleColumnDelete(columnId){
	const newOrder = columnOrder.filter((column) => column!== columnId)
	socket.emit("newContent", {"contentCode": contentCode, "newContent": {"type": "DnD", "room": "perspectives", "title": "Message Sort", "key": contentCode, "columnOrder": newOrder} } ) 
}

function handleColumnListClick(columnId){
	socket.emit("dragKey", {"subGroup":"columns", "key": columnId, "object": dragObject.columns[columnId]})
	socket.emit("newContent", {"contentCode": contentCode, "newContent": {"type": "DnD", "room": "perspectives", "title": "Message Sort", "key": contentCode, "columnOrder": [...columnOrder, columnId]} } ) 
	setAddMode(false)	
}

function getColumnList(){
	console.log(dragObject)
	if(columnOrder.length===0){
		return dragObject.fullColumnList
	} else{
		return(dragObject.fullColumnList.filter(function(obj) { return columnOrder.indexOf(obj) === -1; }))
	}
}


function handleNewColumnAddClick(){
	const newKey = uuidv4()
	const newColumnList = dragObject.fullColumnList
	newColumnList.push(newKey)
	console.log(dragObject.fullColumnList)
	console.log(newColumnList)
	
	socket.emit("dragKey", {"subGroup":"columns", "key": newKey, "object": {"id": newKey, "title": "New Column", "messageIds": [] }})
	socket.emit("newContent", {"contentCode": contentCode, "newContent":{"type": "DnD", "room": "perspectives", "title": "Message Sort", "key": contentCode, "columnOrder": [...columnOrder, newKey]} } )
	socket.emit("dragKey", {"key": "fullColumnList", "object": newColumnList})
	setAddMode(false)

}

 const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = dragObject.columns[source.droppableId];
    const finish = dragObject.columns[destination.droppableId];

    if (start === finish) {
      const newMessageIds = Array.from(start.messageIds);
      newMessageIds.splice(source.index, 1);
      newMessageIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        messageIds: newMessageIds,
      };

      const newState = {
        ...dragObject,
        columns: {
          ...dragObject.columns,
          [newColumn.id]: newColumn,
        },
      };

	  console.log(newState)
	  dragObject = newState
      socket.emit("drag", dragObject)
      return;
    }
 
     const startMessageIds = Array.from(start.messageIds);
    startMessageIds.splice(source.index, 1);
    const newStart = {
      ...start,
      messageIds: startMessageIds,
    };

    const finishMessageIds = Array.from(finish.messageIds);
    finishMessageIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      messageIds: finishMessageIds,
    };

    const newState = {
      ...dragObject,
      columns: {
        ...dragObject.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
	
	dragObject = newState

    socket.emit("drag", newState)
  };

	function handleClick(){
		console.log(columnOrder, contentCode)
	}
	
	return(
		<>
		  <DragDropContext onDragEnd={onDragEnd}>
			<Container>
			  {columnOrder.map(columnId => 
				<Column addMode={addMode} deleteMode={deleteMode} onColumnDelete= {handleColumnDelete} key={dragObject.columns[columnId].id} column={dragObject.columns[columnId]} dragObject={dragObject} />
				)}
				{addMode && <ColumnList  columns = {dragObject.columns}  onNewColumnAddClick = {handleNewColumnAddClick}onColumnListClick = {handleColumnListClick} list = {getColumnList()}/> }
			</Container>
		  </DragDropContext>

			

				<div id="dnd-button-holder" style={{"display":"flex", "flex-direction":"row", "justify-content": "flex-end"}}>

					<button className= "button" onClick={()=> setAddMode(!addMode)}>{addMode ? "cancel" :"+"}</button>
					<button className= "button" onClick={()=> setDeleteMode(!deleteMode)}>{deleteMode ? "cancel": "-"}</button>

				</div>

		</>
)
}

