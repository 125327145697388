import "./chat.css";
import { to_Decrypt, to_Encrypt } from "./aes.js";
import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import Subchat from "./Subchat.js"
import PrivateChatOption from "./PrivateChatOption.js"
import Chatter from "./Chatter.js"
import { v4 as uuidv4 } from 'uuid';
import {SocketContext, socket} from '../socket';


function Chat({ userRole, id, room, userData }) {
  const [text, setText] = useState("");
  const [chatObject, setChatObject] = useState({chatInfo:{"Backroom Chat":{chatMessages:[], sockets:[], newMessage:false, title:"Backroom Chat"}}, chatOrder:["Backroom Chat"]});
  const [chatWindow, setChatWindow] = useState("Backroom Chat")
  const [showChatters, setShowChatters] = useState(false)
  const [showNonChatters, setShowNonChatters] = useState(false)
  const chatRef = useRef({chatInfo:{"Backroom Chat":{chatMessages:[], sockets:[], newMessage:false, title:"Backroom Chat"}}, chatOrder:["Backroom Chat"]})
  const socket = useContext(SocketContext)
  
  
  useEffect(() => {
    socket.on("message", (data) => {
		buildChat(data)
	});
	
	return () => {
		socket.off("message", buildChat)
	}
	   
  }, [socket]);  
  
 
function findSocketIndex(Usocketid, dataChatWindow){
	let socketIndex= ""
	const isSocket = (element) => element ===Usocketid;
	socketIndex = chatRef.current.chatInfo[dataChatWindow].sockets.findIndex(isSocket)
	return socketIndex
	
}

const buildChat = useCallback((data) => {
	  //decrypt
      const ans = to_Decrypt(data.text, data.name);     
	  	  	  
	  //Add new private chat window for new person
	  if(!(data.chatWindow in chatRef.current.chatInfo)){
		  chatRef.current.chatInfo[data.chatWindow] = {chatMessages:[], sockets:[], newMessage:true, title:"Chat "+ chatRef.current.chatOrder.length}
	  }
	
	  //Add private chat to order list if not already included
	  if(!(chatRef.current.chatOrder.includes(data.chatWindow))){
		chatRef.current.chatOrder = [...chatRef.current.chatOrder, data.chatWindow]
	  }
		
	  //add new message and sockets
	  chatRef.current.chatInfo[data.chatWindow].chatMessages.push( { id: data.id, username: data.name, text: ans, type: data.type })
	  chatRef.current.chatInfo[data.chatWindow].sockets = data.sockets
	  
	  //update state
	  console.log(chatRef.current)
	  setChatObject({...chatRef.current});
})

function handleTitleChange(title, chat){
	chatRef.current.chatInfo[chat].title = title
	setChatObject({...chatRef.current})
}

function handleAddPrivateChatParticiantClick(userId){
		
	//don't add if already added. They shouldn't show but just in case :) 
	if(!(chatRef.current.chatInfo[chatWindow].sockets.includes(userData.users[userId].socketid))){	
		chatRef.current.chatInfo[chatWindow].sockets = [...chatRef.current.chatInfo[chatWindow].sockets, userData.users[userId].socketid]
		setChatObject({...chatRef.current})
		const ans = to_Encrypt(userData.users[userId].name+" has joined the chat");
		socket.emit("chat", {"ans": ans, "id": userId, "room":room, "chatWindow":chatWindow, "sockets": chatRef.current.chatInfo[chatWindow].sockets, "type": "announcement"})
	}
	setShowNonChatters(false)

}

function getUIdFromSocket(socket){
	
	let uId = ""
	
	for (const [key, value] of Object.entries(userData.users)) {
		if(userData.users[key].socketid===socket){
			uId= key
		}
	}
	return uId
}
	
function handleShowChattersClick(){
	setShowNonChatters(false)
	setShowChatters(!showChatters)
}

function handleChatDelete(index){

	console.log(index)
	const fChatWindow = chatRef.current.chatOrder[index]
	console.log(fChatWindow)
	const socketIndex = findSocketIndex(userData.users[id].socketid, fChatWindow)
		
	chatRef.current.chatInfo[fChatWindow].sockets = [...chatRef.current.chatInfo[fChatWindow].sockets.slice(0,socketIndex), ...chatRef.current.chatInfo[fChatWindow].sockets.slice(socketIndex+1)]
	const ans = to_Encrypt(userData.users[id].name+" has left the chat");
	console.log(chatRef.current.chatInfo[chatWindow].sockets)
	socket.emit("chat", {"ans": ans, "id": id, "room":room, "chatWindow":fChatWindow, "sockets": chatRef.current.chatInfo[chatWindow].sockets, "type": "announcement"})
	chatRef.current.chatOrder = [...chatRef.current.chatOrder.slice(0, index), ...chatRef.current.chatOrder.slice(index + 1)];
	setChatWindow("Backroom Chat")
	setChatObject({...chatRef.current})
	console.log(chatRef.current)
	
	
}

function handleAddChatterPlusClick(){
	setShowChatters(false)
	setShowNonChatters(!showNonChatters)
}

function handleChatAdd(){
	
	//encrypt here
    const ans = to_Encrypt(userData.users[id].name+" has joined the chat");
   	const newChat = uuidv4()	
	socket.emit("chat", {"ans": ans, "id": id, "room":room, "chatWindow":newChat, "sockets":[userData.users[id].socketid], "type": "announcement"})
}

function handleChatChange(chat){
	setShowChatters(false)
	setChatWindow(chat)
	
	//stop blinking
	chatRef.current.chatInfo[chat].newMessage = false
	setChatObject({...chatRef.current})	
}


  const sendData = () => {
    if (text !== "") {
      //encrypt here
      const ans = to_Encrypt(text);
      socket.emit("chat", {"ans":ans, "id":id, "room":room, "chatWindow":chatWindow, "sockets":chatRef.current.chatInfo[chatWindow].sockets, "type": "chat"});
      setText("");
    }
  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [chatRef.current.chatInfo[chatWindow]]);

  return (
  <>
  {userRole>1 &&
  <>
    <div className="chat" >
	
      <div className="title" style={{"background-color":"#2d343e","text-align":"center", "color": "white", "font-size": "12px", "margin-left":"2px", "border-style":"inset"}}>
        <h2>
		{chatObject.chatInfo[chatWindow].title}		
		 {chatWindow!=="Backroom Chat" && <button className= "button" style={{"float":"right"}} onClick={()=> handleShowChattersClick()}>{showChatters ? "-":"👤"}</button>} 
		 {chatWindow!=="Backroom Chat" && <button className= "button" style={{"float":"right"}} onClick={()=> handleAddChatterPlusClick()}>{showNonChatters ? "-":"Invite"}</button>}
		 
		 {(chatWindow!=="Backroom Chat" && showChatters) &&
			<ul style ={{"display":"inline-block", "font-size":"10px", "width": "75%", "margin-top":"2px", "margin-bottom":"2px", "background-color": "#BDBDBD", "border-style": "inset"}}>
				
				{chatObject.chatInfo[chatWindow].sockets.map((socket, index)=>
					
					<Chatter
					index = {index}
					name = {userData.users[getUIdFromSocket(socket)].name}
					id = {userData.users[getUIdFromSocket(socket)].id}
					key = {userData.users[getUIdFromSocket(socket)].id}
					
					/>
					)}

		 </ul>}
		 
		 
		 
	     {(chatWindow!=="Backroom Chat" && showNonChatters) && 
			<ul style ={{"display":"inline-block", "font-size":"10px", "width": "75%", "margin-top":"2px", "margin-bottom":"2px", "background-color": "#dcdcdc", "margin-left":"2px", "border-style": "inset"}}>
				{userData.roles.Hosts.length + userData.roles.Observers.length === chatObject.chatInfo[chatWindow].sockets.length && <div style={{"padding":"1px", "color":"black", "margin":"1px", "border-style": "inset", "width":"75%", "cursor":"pointer", "background-color":"#D3D3D3"}}>No remaining attendees can be invited</div>}
			{userData.roles.Hosts.map((host)=>
			<>
				{(!(chatObject.chatInfo[chatWindow].sockets.includes(userData.users[host].socketid))) &&
				<PrivateChatOption 
				name = {userData.users[host].name}
				id = {userData.users[host].id}
				key = {userData.users[host].id}
				onAddPrivateChatParticiantClick = {handleAddPrivateChatParticiantClick}
				
				/>
				}
			</>
			)}
			{userData.roles.Observers.map((observer)=>
			<>
				{(!(chatObject.chatInfo[chatWindow].sockets.includes(userData.users[observer].socketid))) &&
				<PrivateChatOption 
				name = {userData.users[observer].name}
				id = {userData.users[observer].id}
				key = {userData.users[observer].id}
				onAddPrivateChatParticiantClick = {handleAddPrivateChatParticiantClick}
				
				/>
				}
			</>
			)}
			</ul>
		 
		 }
		 
        </h2>
      </div>
	  <div id="message-holder" style={{"border-style":"inset", "overflow-y":"scroll", "height":"70%", "padding":"5px", "background-color": "white"}}>
      <div className="chat-message" style={{"margin":"0px", "overflow-x":"hidden"}}>
        {chatObject.chatInfo[chatWindow].chatMessages.map((i) => {
          if (i.type === `chat`) {
            return (
              <div className="message">
		  <p style ={{"margin":"0px"}}> <b>{i.username}</b>: {i.text} </p>
              </div>
            );
			
			} else if (i.type === `announcement`) {
				
			return (
              <div className="announcement">
                <p><i>{i.text}</i></p>
              </div>
            );
          }
		  
        })}
        <div ref={messagesEndRef} />
	  </div>
      </div>
      <div className="send">
        <input
          placeholder="enter your message"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              sendData();
            }
          }}
        ></input>
        <button className= "btn-1" onClick={sendData}>Send</button>
      </div>
	</div>
	<div id="subChatMenus" className="subchat" style={{ "margin-left":"12px", "margin-right": "20px", "overflow-x":"auto", "display":"flex", "display-direction":"row"}}>
			{chatObject.chatOrder.map((chat, index) =>
				<Subchat
				 index = {index}
				 chat = {chat}
				 title = {chatObject.chatInfo[chat].title}
				 key = {chat}
				 onChatChange = {handleChatChange}
				 onChatDelete = {handleChatDelete}
				 onTitleChange ={handleTitleChange}
				 blinking = {chatObject.chatInfo[chat].newMessage}
				/>
			)}
	</div>
	<button style={{"float":"right"}} onClick={() => handleChatAdd()}>+</button>
	</>
	}
	</>
  );
}
export default Chat;
