import React, {useState, useContext} from 'react';
import StimuliDisplay from '../StimuliDisplay/StimuliDisplay';
import DnD2 from '../DnD/DnD2';
import { Rnd } from "react-rnd";
import Meeting from '../Meeting/Meeting';
import UploadBox from '../UploadBox/UploadBox';
import MultipleChoicePoll from '../Poll/MultipleChoicePoll';
import MultipleAnswerPoll from '../Poll/MultipleAnswerPoll';
import OpenEndedPoll from '../Poll/OpenEndedPoll';
import DnDPoll from '../Poll/DnDPoll';
import {SocketContext} from '../socket';

//REMOVE DECK AND IMAGE DISTINCTION

export default function ContentDisplayHandler({layouts, content, dragObject, getLocalWidth, getLocalHeight, getLocalX, getLocalY, userRole, onContentRemoveClick, updateWidthHeight, updatePosition, currentLayout, onPreviousClick, onNextClick, handleResizeStop}){

	const socket = useContext(SocketContext)
	const [drawMode, setDrawMode] = useState(true)
			
function resizer(size, resize){
	return(Number(size.substring(0, size.length - 2)- resize))+"px"	
}

function handleZoomClick(contentCode){
	const newContent = content.content[contentCode]
	if(newContent.mode !== "zoom"){
		newContent.mode = "zoom"
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent})
		
	} else{
		newContent.mode = "none"
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent})
	}
	
}

function handleDrawClick(contentCode){
	const newContent = content.content[contentCode]
	if(newContent.mode !== "draw"){
		newContent.mode = "draw"
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent})
		
	} else{
		newContent.mode = "none"
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent})
	}
	
}

function handleZoomChange(contentCode, zoomInfo){
	const newContent = content.content[contentCode]
	newContent.zoom = zoomInfo

	socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent})
	
}

return(
		<>
		{layouts.Layouts[currentLayout].list.map((contentCode) =>	
			<Rnd
				style={{"padding-left":"5px", "padding-right":"5px", "background-color":"#D3D3D3", "border-style":"outset", "overflow": "hidden"}}
				bounds="parent"
				dragHandleClassName="dragger"
				minWidth="175px"
				minHeight="175px"
				disableDragging={userRole===1}
				size={{ width: getLocalWidth(contentCode),  height: getLocalHeight(contentCode) }}
				position={{ x: getLocalX(contentCode), y: getLocalY(contentCode) }}
				onDragStop={(e, d) => { updatePosition(d.x, d.y, contentCode) }}
				onResizeStop={(e, direction, ref, delta, position) => {
					handleResizeStop(ref.style.width, ref.style.height, contentCode, position)
				}}
				onResize={(e, d, ref)=>{
					updateWidthHeight(ref.style.width, ref.style.height, contentCode)
				}}

				>
				

				<div className="dragger" style={{"margin":"10px","text-align":"center", "font-size":"16px", "background-color":"#2d343e", "padding":"5px", "cursor":"pointer", "color":"white","border-style":"inset"}} >
					<b>{content.content[contentCode].title}</b>
						{userRole>1 && <button style={{"float":"right", "margin-left": "10px"}} onClick={()=> onContentRemoveClick(contentCode)}> x </button>}
				</div>
				
				{(content.content[contentCode].type==="camera") &&
					<>
						<Meeting 
							userRole = {userRole}
							width = {resizer(getLocalWidth(contentCode), 200)}
							height = {resizer(getLocalHeight(contentCode), 100)}
						/>
					</>
				}
				
				{(content.content[contentCode].type==="empty") && 
					<UploadBox onUploadedUrlImageUpdate = {{}} width = {resizer(getLocalWidth(contentCode), 50)} height = {resizer(getLocalHeight(contentCode), 110)} contentCode={contentCode} />
				}
				
				{(content.content[contentCode].type==="image") && 
						<StimuliDisplay 
							mode={content.content[contentCode].mode}
							scale = {content.content[contentCode].zoom.scale}
							translation = {content.content[contentCode].zoom.translation}
							url = {content.content[contentCode].urls[0]}
							userRole = {userRole}
							contentCode = {contentCode}
							width = {resizer(getLocalWidth(contentCode), 50)}
							height = {resizer(getLocalHeight(contentCode), 110)}
						/>
				}
				
				{(content.content[contentCode].type==="DnD") &&
					<>
					<DnD2 dragObject = {dragObject} columnOrder = {content.content[contentCode].columnOrder} contentCode = {contentCode} width = {resizer(getLocalWidth(contentCode), 50)}  height = {resizer(getLocalHeight(contentCode), 110)}/>
					</>
				}
				
				{(content.content[contentCode].type==="MC") &&
					<>
						<MultipleChoicePoll 
							room = {content.content[contentCode].room}
							question = {content.content[contentCode].question} 
							answers = {content.content[contentCode].answers} 
							type= {content.content[contentCode].type}
							contentCode = {contentCode} 
							userRole= {userRole}
							width = {resizer(getLocalWidth(contentCode), 50)}  
							height = {resizer(getLocalHeight(contentCode), 150)}/>
					</>
								
								}
								
					{content.content[contentCode].type==="MA" &&
						<>
							<MultipleAnswerPoll 
								room = {content.content[contentCode].room}
								question = {content.content[contentCode].question} 
								answers = {content.content[contentCode].answers} 
								type= {content.content[contentCode].type}
								contentCode = {contentCode} 
								userRole= {userRole}
								width = {resizer(getLocalWidth(contentCode), 50)}  
								height = {resizer(getLocalHeight(contentCode), 150)}/>
						</>
				}
				
				{content.content[contentCode].type==="OE" &&
						<>
							<OpenEndedPoll
								room = {content.content[contentCode].room}
								question = {content.content[contentCode].question} 
								answers = {content.content[contentCode].answers} 
								type= {content.content[contentCode].type}
								contentCode = {contentCode} 
								userRole= {userRole}
								width = {resizer(getLocalWidth(contentCode), 50)}  
								height = {resizer(getLocalHeight(contentCode), 150)}/>
						</>
				}
				
				{content.content[contentCode].type==="DD" &&
						<>
							<DnDPoll
								room = {content.content[contentCode].room}
								question = {content.content[contentCode].question} 
								answers = {content.content[contentCode].answers} 
								type= {content.content[contentCode].type}
								contentCode = {contentCode} 
								userRole= {userRole}
								width = {resizer(getLocalWidth(contentCode), 50)}  
								height = {resizer(getLocalHeight(contentCode), 150)}/>
						</>
				}
				
				{(content.content[contentCode].type==="deck") &&
					<>
						<StimuliDisplay 
							mode={content.content[contentCode].mode}
							scale = {content.content[contentCode].zoom.scale}
							translation = {content.content[contentCode].zoom.translation}
							url = {content.content[contentCode].urls[content.content[contentCode].urlIndex]}
							pageNumber = {content.content[contentCode].urlIndex}
							userRole = {userRole}
							contentCode ={contentCode}
							width = {resizer(getLocalWidth(contentCode), 50)}
							height = {resizer(getLocalHeight(contentCode), 110)}
							onZoomChange= {handleZoomChange}
						/>
						
						<div className = "buttonHolder" style={{"margin-left":"10px","display":"block"}}>
							<button className = "btn-1" disabled={content.content[contentCode].urlIndex===0} onClick={()=> onPreviousClick(contentCode)}>Previous</button>	
							<button className = "btn-1" disabled={content.content[contentCode].urlIndex === content.content[contentCode].urls.length-1} onClick={()=> onNextClick(contentCode)}>Next</button>
							{((content.content[contentCode].type==="image") || (content.content[contentCode].type==="deck")) && <button className = "button" style={{"float":"right"}} onClick={()=>handleZoomClick(contentCode)}>Zoom</button>}
							{((content.content[contentCode].type==="image") || (content.content[contentCode].type==="deck")) && <button className = "button" style={{"float":"right"}} onClick={()=>handleDrawClick(contentCode)}>Draw</button>}
						</div>
						
						
					</>
				}
			</Rnd>
		)}
		</>
)
}