import React,{useState, useContext} from 'react';	
import './StimuliDisplay.css'				
import reactImageSize from 'react-image-size';
import { MapInteractionCSS } from 'react-map-interaction';
import {SocketContext} from '../socket';
import Board from '../Board/Board'

export default function StimuliDisplay({url, userRole, scale,translation, pageNumber, drawMode, mode, contentCode, height, width, onZoomChange}){

	const socket = useContext(SocketContext)

function isHost(){
	if(userRole===3){
		return true
	} else{
		return false
	}
}

return(

	<>

	
		{(mode==="zoom") &&
			<div id= "displayHolder" style={{ "overflow":"hidden", "text-align": "center", "height": height, "width": width,"vertical-align": "middle", "backgroundColor": "#BDBDBD", "display": "block","position": "relative", "border-style":"inset", "margin":"5px" }}>

						<MapInteractionCSS
							disableZoom={!isHost()}
							disablePan={!isHost()}
							showControls={isHost()}
							value={{scale,translation}}
							onChange={({ scale, translation }) => {
								onZoomChange(contentCode, {scale, translation})
								console.log({scale,translation})
							}}
						
						>
						  <img src= {url} />
						</MapInteractionCSS>
				
		</div>
		}
		
		{(mode==="none") &&
			<div id= "displayHolder" style={{"overflow":"hidden", "text-align": "center", "backgroundColor": "#BDBDBD", "height": height, "width": width, "vertical-align": "middle", backgroundImage: "url(\""+url+"\")", "background-size": "contain" , "background-repeat": "no-repeat", "background-position": "center", "display": "block", "position": "relative", "border-style":"inset", "margin":"5px" }}>

			</div>
		}
		
		{(mode==="draw") && 
		<div id= "displayHolder" style={{"overflow":"hidden", "text-align": "center", "backgroundColor": "#BDBDBD", "height": height, "width": width, "vertical-align": "middle", "display": "block", "position": "relative", "border-style":"inset", "margin":"5px" }}>
			<Board 
			contentCode ={contentCode} 
			pageNumber ={pageNumber}  
			drawMode={drawMode} 
			width = {width.substring(0, width.length - 2)} 
			height = {height.substring(0, height.length - 2)} 
			imgSrc = {url}
			/>

		</div>
		}
		
	
</>

)
}