
import {
  useMeetingManager,
  useMeetingStatus,
} from "amazon-chime-sdk-component-library-react";

import { NavigationProvider } from './NavigationProvider';
import MeetingView from "./MeetingView";
import React, { useEffect, useContext } from "react";
import './Meeting.css';
import {SocketContext, socket} from '../socket';
import { useParams } from "react-router-dom";




export default function Meeting({width, height}) {
  const meetingStatus = useMeetingStatus();
  const meetingManager = useMeetingManager();
  const socket = useContext(SocketContext)
  const { Id } = useParams();
  
  
  useEffect(() => {
    socket.on("meeting", (meeting) => {
		if(meeting===null){
			joinMeeting("https://70yw942221.execute-api.us-east-1.amazonaws.com/Prod/join?clientId=j20n7fZ4hT2mheb4i5OTFg==&name="+ Id)
		}else{
			joinMeeting("https://70yw942221.execute-api.us-east-1.amazonaws.com/Prod/join?clientId=j20n7fZ4hT2mheb4i5OTFg==&name="+ Id + "&meetingId="+meeting)
		}
	
	});
	
	return () => {
		socket.off("meeting", joinMeeting)
	}
	
	
  }, [socket]);

	const payload = {
    title: "event_6275",
    name: "test",
    region: "us-east-1"
  };
  
  
  
  const joinMeeting = async (url) => {
    // Fetch the meeting and attendee data from your server
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(payload) // body data type must match "Content-Type" header
    });
	
    const data = await response.json();

    const joinData = {
      meetingInfo: data.Info.Meeting.Meeting,
      attendeeInfo: data.Info.Attendee.Attendee
    };
	
	let meeting = data.Info.Meeting.Meeting.MeetingId
	socket.emit("meeting", meeting)

    // Use the join API to create a meeting session
    await meetingManager.join(joinData);
    
    // At this point you can let users setup their devices, or start the session immediately
    await meetingManager.start();
    // toggleMute();
  };
  return (

            <NavigationProvider>
				{(meetingStatus===0)?"Joining Meeting...":""}
			    <MeetingView  
					width = {width}
					height = {height}
				/>		
			</NavigationProvider>
  );
}
