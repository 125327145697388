import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styled from 'styled-components';
import './UploadBox.css'

export default function UploadBox(props) {
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState(null);
  const fileTypes = ["JPEG", "PNG", "GIF", "PPT", "PPTX", "PDF"];
    
  const handleUploadClick = () => {
	 let formData = new FormData();
	 formData.append("file", file);
	 formData.append("contentCode", props.contentCode)

		setIsUploading(true)
		console.log(formData, file)
	
		
		fetch("https://docs.perspectives.icearp.net:8080/upload", {
			method: 'POST',
			body: formData,                                  // ***
		})
		.then((response)=>{
			if(response.status===200){
				console.log("success")
			}

		})
		.catch((error) =>{
			console.log(error)
			setIsUploading(false)
			
		})
  };
  
 	const selectFile = (event) => {
		let formData = new FormData();
		 formData.append("file", event.target.files[0]);
		 formData.append("contentCode", props.contentCode)

			setIsUploading(true)
			console.log(formData, file)
		
			
			fetch("https://docs.perspectives.icearp.net:8080/upload", {
				method: 'POST',
				body: formData,                                  // ***
			})
			.then((response)=>response.json())
			.then(response => {
					console.log(response)
					props.onUploadedUrlImageUpdate(response.message)
				}
			)
			.catch((error) =>{
				console.log(error)
				setIsUploading(false)
				
			})
	};  
  
  return (
	<>
		<input 
			type="file"
			onChange={selectFile}
		/>
		{isUploading &&  <div style={{"color": "white"}}>Uploading... <img src = "https://raw.githubusercontent.com/SamHerbert/SVG-Loaders/5deed925369e57e9c58ba576ce303466984db501/svg-loaders/spinning-circles.svg" style={{"horizontal-align": "middle", "height": "20px", "width": "20px", "display": "inline-block", "margin": "10px"}}/></div>}
	</>
  );
}

