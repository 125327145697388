import React, {useState, useContext} from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import ImageDnD from './ImageDnD'
import {SocketContext} from '../socket';
import './Message.css';



const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  display: flex;
  margin-bottom: 8px;
  left: auto !important; 
  top: auto !important; 
  background-color: ${props => (props.isDragging ? 'lightgreen' : 'white')};
  align-items: center;
  overflow:hidden;
  justify-content: center;
`;

export default function Message({key, message, index, deleteMode, onMessageDeleteClick}){
 	const socket = useContext(SocketContext)
	const [toggle, setToggle] = useState(true);
	const [text, setText] = useState(message.content)
   
   
   function handleMessageUpdate() {
	socket.emit("dragKey", {"subGroup":"messages", "key": message.id, "object":{"id": message.id, "type": "text", "content": text}})
  }
 
   function handleChange(event) {
	setText(event.target.value);
  }
 
   function toggleInput() {
    setToggle(false);

  }
  
  function handleCancel(){
	setToggle(true)
	setText(message.content)
  }
  
    return (
      <Draggable key = {message.content} draggableId={message.id} index={index}>
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
			isDragging={snapshot.isDragging}
          >
		  
		  {message.type==="text" ?
			(<>
			{toggle ? (
			<div onDoubleClick={toggleInput}>{text}</div>
			) : (
			<div style = {{"display":"block"}}>
			<div>
				<input
				className="message"
				type="text"
				value={text}
				onChange={handleChange}
				/>
			</div>
			<button onClick={()=>handleMessageUpdate()}>Update</button>
			<button onClick={()=>handleCancel()}>Cancel</button> 
			</div>
			)}
		  </>)
		  
		  :<ImageDnD id = {message.id} imageSrc = {message.content} /> 
		 
        }
		{deleteMode && <button onClick={()=>onMessageDeleteClick(message.id)}>x</button>}
		 </Container>

		)}
	      </Draggable>
)}