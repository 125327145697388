import React from 'react';

export default function ColumnList({list, columns, onNewColumnAddClick, onColumnListClick}){
	
	return(
		<>
			{list.length === 0 && <p>No other existing columns</p>}
			<div style={{"display":"flex", "flex-direction":"column"}}>
						<button className="button" onClick={()=>onNewColumnAddClick()}> + New Column </button>
				{list.map(columnId =>
						<button className="button" onClick={()=>onColumnListClick(columnId)}> + {columns[columnId].title}</button>
				)}
			</div>
		</>
	)	
}
