import React, {useEffect, useCallback, useContext} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import './AttendeeList.css';
import Attendee from './Attendee/Attendee.js'
import { useParams } from "react-router-dom";
import {SocketContext} from '../socket';
export default function AttendeeList({ onRoleChange, userRole, userData, buildUserData}){

const { Id } = useParams();
const socket = useContext(SocketContext)
	
useEffect(() => {
	  	  
    socket.on("Updated User Data", (data) => {
		detectRoleChange(data.userData)
		console.log(data)
			});
			
	return () => {
		socket.off("Updated User Data", detectRoleChange)
	}
	
 }, [socket]);
 
const detectRoleChange = useCallback((moveData) => {
	userData.roleOrder.forEach((roleId) =>{
		moveData.roles[roleId].forEach((userId)=>{
				if(userId===Id){
					newUserRole(roleId)
				}
			})
		})
		
	buildUserData(moveData.users, moveData.roles, moveData.roleOrder)
	
})


 const broadcastUserUpdate  = useCallback((moveData) =>  {
      socket.emit("move", moveData);
    });

const reorder = (list, startIndex, endIndex) => {

	const newArray = list.filter(userName => userName !== list[startIndex])
	const finalArray = [...newArray.slice(0, endIndex), list[startIndex], ...newArray.slice(endIndex)]
    return finalArray;
};

const notHost=() =>{
	if(Number(userRole)!==3){
		return true;
	} else {
		return false;
	}
}

function getRoleId(role){

		if(role === 'Hosts'){
			return 3
		} else if(role === 'Observers'){
			return 2
		} else {
			return 1
		}
}

const newUserRole = (role) =>{
	
		if(role === 'Hosts'){
			onRoleChange(3)
		} else if(role === 'Observers'){
			onRoleChange(2)
		} else {
			onRoleChange(1)
		}
}

const move = (sourceList, destinationList, source, destination) => {
    
	if(source===destination){
		return;
	}
	
	const newSource = sourceList.filter(sItem => sItem !== sourceList[source.index])
	const newDestination = [...destinationList.slice(0, destination.index), sourceList[source.index], ...destinationList.slice(destination.index) ]
    const result = {};
	
	result['Hosts'] = userData.roles.Hosts
	result['Observers'] = userData.roles.Observers
	result['Participants'] = userData.roles.Participants
	
    result[source.droppableId] = newSource;
    result[destination.droppableId] = newDestination;

	if(sourceList[source.index]===Id){
		newUserRole(destination.droppableId)
		
	}

    return result;
};


	const getList = (listId) => {
				
			if(listId==="Hosts"){
				return userData.roles.Hosts;
			} else if(listId==="Observers") {
				return userData.roles.Observers;
			} else if(listId==="Participants"){	
				return userData.roles.Participants;
			}
}


	const onDragEnd = (result) => {
        const { source, destination } = result;


        // dropped outside the list
        if (!destination) {
            return;
        }
	
        if (source.droppableId === destination.droppableId) {
            
			if(source.index!==destination.index){
			
			const items = reorder(
                getList(destination.droppableId),
                source.index,
                destination.index
            );
		
			if(destination.droppableId==="Hosts"){
			
				const roles = {'Hosts': [items], 'Observers': userData.roles.Observers, 'Participants': userData.roles.Participants}
				buildUserData( userData.users, roles, userData.roleOrder)
			
			} else if(destination.droppableId==="Observers"){
				
				const roles = {'Hosts': userData.roles.Hosts,'Observers': [items], 'Participants': userData.roles.Participants}
				buildUserData(userData.users, roles, userData.roleOrder)
			
			} else {
				const roles = {'Hosts': userData.roles.Hosts,'Observers': userData.roles.Observers,'Participants': [items]}
				buildUserData(userData.users, roles, userData.roleOrder)
			}}
			
			

        } else {		
			
			const users = userData.users
			users[getList(source.droppableId)[source.index]].role = getRoleId(destination.droppableId)
			
			
			const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );
			
			const roles = {'Hosts': result.Hosts, 'Observers': result.Observers, 'Participants': result.Participants}

			const newUserData = {"users": userData.users,"roles": roles,"roleOrder": userData.roleOrder}
			buildUserData(userData.users, roles, userData.roleOrder)
			broadcastUserUpdate(newUserData)
		}
			
    };
	 
return(
	<>
	{userRole>1 &&
	<div className = "attendeeList" style={ notHost() ? { 'pointer-events': 'none', "overflow-y":"auto", "overflow-x":"hidden"} : {"overflow-y":"auto", "overflow-x":"hidden"}} >
		<DragDropContext onDragEnd={onDragEnd}>
			<div id ="attendeeRow">
				{userData.roleOrder.map((roleId) => 
						<Droppable 
							droppableId={roleId}>
								{(provided) => (
									<div
										{...provided.droppableProps}                           
									   ref={provided.innerRef}>
										
										<div id= "heading">
											<b>{roleId}</b>
										</div>
										<div className= "dropRow" style={{"margin":"3px"}}>
											{getList(roleId).map((userId, index) => (
												<div id="isMe" style={userId===Id?{'font-style': 'italic'}:{}}>
													<Attendee
														key = {userData.users[userId].displayName}
														id = {userId}
														name = {userData.users[userId].displayName}
														index = {index} />
												</div>
											))}
											
										</div>
									{provided.placeholder}
									</div>
									)}
						</Droppable>
			 )}
		 	</div>

		 
	</DragDropContext>
	</div>
	}
	</>
	
	);	
}