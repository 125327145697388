export default {
    "Layouts": {
        "axzjlasdu": {
			"list":["camera"], 
			"title": "Welcome",
			"key": "axzjlasdu",
			"thumbnail" : "http://localhost:8080/files/Blue.PNG",
			"position": {
				"camera": {
					"coordinates":{
						"x":0,
						"y":0,
						"width": "320px",
						"height": "200px"
					}
				},
				}		
			},
			
			"layoutOrder": [ "axzjlasdu"]
		}
	}