import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Message from './Message';
import {SocketContext} from '../socket';
import { v4 as uuidv4 } from 'uuid';

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  min-width: 30%;
  display: flex;
  height: auto;
  flex-direction: column;

`;
const Title = styled.h3`
  padding: 8px;
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : '#6699cc')};
  color: white;
  margin: 0px;
`;
const MessageList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')};
  flex-grow: 1;
  min-height: 100px;
  overflow-y: scroll;

`;



export default function Column({key, addMode, deleteMode, column, dragObject, onColumnDelete}){
	const [toggle, setToggle] = useState(true);
	const [messageDeleteMode, setMessageDeleteMode] = useState(false)
	const [title, setTitle] = useState(column.title)
	
	const socket = useContext(SocketContext)
	const messages = column.messageIds.map(
	  messageId => dragObject.messages[messageId]
	);

	function handleTextAdd(id){
		const newKey = uuidv4()
		socket.emit("dragKey", {"subGroup":"messages", "key": newKey, "object": {"id": newKey, "type":"text", "content":"Enter Message Here" }})
		socket.emit("dragKey", {"subGroup":"columns", "key": column.id, "object": {"id": column.id, "title":column.title, "messageIds": [...column.messageIds, newKey] }})
	}
	
	function handleMessageDeleteClick(id){
		const newMessageList = column.messageIds.filter(messageId=>messageId!==id)
		socket.emit("dragKey", {"subGroup":"columns", "key": column.id, "object": {"id": column.id, "title":column.title, "messageIds": newMessageList }})
	}
	
	function handleImageAdd(id){
		const newKey = uuidv4()
		socket.emit("dragKey", {"subGroup":"messages", "key": newKey, "object": {"id": newKey, "type":"image", "content":"" }})
		socket.emit("dragKey", {"subGroup":"columns", "key": column.id, "object": {"id": column.id, "title":column.title, "messageIds": [...column.messageIds, newKey] }})
	}

	function handleTitleChange(event){
		setTitle(event.target.value);
	}
	
	function handleTitleUpdate(){
		socket.emit("dragKey", {"subGroup":"columns", "key": column.id, "object":{"id": column.id, "title": title, "messageIds": column.messageIds}})
	}
	
	function toggleInput() {
		setToggle(false);
	}

	function handleCancel(){
		setToggle(true)
		setTitle(column.title)
	}

return(

	  <Container>
        <Title>
			{toggle ? (
				<div style={{"font-size":"18px", "color":"white", "text-align":"center"}} onDoubleClick={toggleInput}>{title}</div>
				) : (			
				<>
				<div style = {{"display":"block"}}>
					<input
					style={{"font-size":"18px", "color":"white", "text-align":"center"}}
					type="text"
					value={title}
					onChange={handleTitleChange}
					/>
				</div>
					<button className= "button" onClick={()=>handleTitleUpdate()}>Update</button>
					<button className= "button" onClick={()=>handleCancel()}>Cancel</button> 
				</>
				)}
			
			{deleteMode && <button className= "button" style={{"float":"right"}} onClick={()=> onColumnDelete(column.id)}>x</button>}


		</Title>

        <Droppable droppableId={column.id}>
          {(provided, snapshot) => (
            <MessageList
              ref={provided.innerRef}
              {...provided.droppableProps}
			  isDraggingOver={snapshot.isDraggingOver}
            >
              {messages.map((message, index) => (
				<Message key={message.id}  deleteMode = {deleteMode} message={message} index={index} onMessageDeleteClick={handleMessageDeleteClick}/>
              ))}
              {provided.placeholder}
            </MessageList>
          )}
        </Droppable>
		{addMode && 
		<div id= "columnButtonHolder">
			<button className= "button" onClick={()=> handleTextAdd()}>Add Text</button>
			<button className= "button" onClick={()=> handleImageAdd()}>Add Image</button>
		</div>}
      </Container>

)
}
