import React, {useState} from 'react';
import MeetingControls from '../MeetingControls/MeetingControls';
import './TopMenu.css'

export default function TopMenu({onAddContentClick, userRole, onDuplicateContentClick, content}){
	
	const [dropMenu, setDropMenu] = useState(false)
	const [doubleDropMenu, setDoubleDropMenu] = useState("none")
	
	
	function getContentList(type){
		
		console.log(content)
		
		let cList= []
		
		content.contentList.forEach(contentCode => {
			if(type.includes(content[contentCode].type)){
				cList.push(contentCode)
			}
		})
		
		return(cList)
	}
	
	return(
		<div id="meetingHeader" style={{"display": "flex", "align-items":"center", "justify-content": "center","background-color":"#2d343e", "border-style":"inset", "padding":"3px", "text-align":"center", "margin":"5px"}}>
			{userRole!==2 && 
			<MeetingControls />}
			{userRole === 3 && 
			<div id="addContentMenu" style={{"margin-left": "auto", "display":"inline-block"}}>

				{!dropMenu && <button className="btn-2" onClick={()=>setDropMenu(true)}> + </button>}
					
					{dropMenu && 
					<>
					<div className="addImageDocument" style={{"background-color":"DBDBDB", "display":"inline-block", "margin":"0px"}}>
						{doubleDropMenu!=="Image/Document" ?
							(<button className="btn-2" onClick={()=>setDoubleDropMenu("Image/Document")}> Add Image/Document</button>)
							:
							(
							<>
								<button className="btn-2" onClick={()=>setDoubleDropMenu("none")}> Add Image/Document</button>
								<ul style={{"position":"absolute", "margin":"0px", "padding":"0px", "background-color":"#DBDBDB", "width":"10%","list-style-type": "none"}}>
									<li><button className="btn-2" onClick={()=>onAddContentClick("empty")}>New</button></li>
										{getContentList(["deck","Image"]).map(contentCode =>
										<li><button className="btn-2" onClick={()=>onDuplicateContentClick(contentCode)}>{content[contentCode].title}</button></li>
										)}
								</ul>
							</>
							)
						}
					</div>
					<div id="addCamera" style={{"background-color":"DBDBDB", "display":"inline-block"}}>
						<button className="btn-2" onClick={()=>onAddContentClick("camera")}> Add Camera</button>
					</div>
					<div id="addDnD" style={{"background-color":"DBDBDB","display":"inline-block"}}>
						<button className="btn-2" onClick={()=>onAddContentClick("DnD")}> Add Sort</button>
					</div>
					<div id="addPoll" style={{"background-color":"DBDBDB", "display":"inline-block"}}>
						{doubleDropMenu!=="poll" ?
							(<button className="btn-2" onClick={()=>setDoubleDropMenu("poll")}> Poll</button>)
							:
							(
							<>
								<button className="btn-2" onClick={()=>setDoubleDropMenu("none")}> Poll</button>
								<ul style={{"position":"absolute", "margin":"0px", "padding":"0px", "background-color":"#DBDBDB","width":"10%", "list-style-type": "none"}}>
									<li><button className="btn-2" onClick={()=>onAddContentClick("poll")}>New</button></li> 
										{getContentList(["MC","MA","OE","DD"]).map(contentCode =>
										<li><button className="btn-2" onClick={()=>onDuplicateContentClick(contentCode)}>{content[contentCode].title}</button></li> 
										)}
								</ul>
							</>
							)
						}
						
					</div>
						<button className="btn-2" onClick={()=>setDropMenu(false)}> - </button>
					</>}
			</div>
			}
		</div>
	)
	
}