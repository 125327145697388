import React from "react";
import './Subchat.css';

function Subchat({index, title, chat, onChatChange, onChatDelete, blinking, onTitleChange}) {

  const [toggle, setToggle] = React.useState(true);
  const [text, setText] = React.useState(title);

  function toggleInput() {
    setToggle(false);
  }

  function handleChange(event) {
	setText(event.target.value);
    onTitleChange(event.target.value,chat);
  }

	function isBlinking(){
		if(blinking){
			return "blink"
		} else {
			return "notBlink"
		}
	}
	
  return (

		<div 
		style ={{ "font-weight":"bold", "color":"white", "display":"inline-block", "font-size":"10px", "margin":"0px", "padding":"5px", "background-color": "#6699cc", "border-style": "inset"}}
		className={isBlinking()}
		onClick={() => onChatChange(chat)}
		>
		
		{toggle ? (
			<div onDoubleClick={toggleInput}>{text}</div>
			) : (
			<input 
			type="text"
			className={`subchat ${isBlinking()}`}
			value={text} 
			onChange={handleChange} />
			)}
		{chat!=="Backroom Chat"&&<button onClick={() => onChatDelete(index)}>x</button>}
		
		</div>
		
		
  );
}

export default Subchat;
