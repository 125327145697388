import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from "styled-components";
import {
  MeetingProvider,
  lightTheme
} from "amazon-chime-sdk-component-library-react";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
       <MeetingProvider>
        <App />
      </MeetingProvider>
	</ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
