import React from 'react';
import './ResponseView.css';

export default function ResponseView({rView, type}){

function handleClick(){
	console.log(rView)
}

	return(
		<>
			{rView===null ? 
			(<p> No Responses yet </p>
			) :
			(
			
				<table style={{"margin":"10px"}}>

				{type==="DD" &&
					<>
					{rView.userList.map((rsp) =>
							<>
							<tr><td>{rView[rsp].name}</td>
							
							{rView[rsp].response.map((a) =>
								<td>{a}</td>
							)}
							</tr>
							</>			
							
						)}
					</>
				}

				{type!=="DD" &&
					<>
					<tr><th>Name</th><th>Responses</th></tr>
						{rView.userList.map((rsp) =>
							<tr> <td>{rView[rsp].name}</td> 
							
							{rView[rsp].response.map((a) =>
									<td>{a}</td> 
							)}
											
							</tr>
						)}
					</>
				}
				</table>
				)}
				
		</>
	)
}