// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import {
  VideoTileGrid,
  VideoTile,
  UserActivityProvider
} from 'amazon-chime-sdk-component-library-react';

import MeetingDetails from './MeetingDetails';
import { useNavigation } from './NavigationProvider';
import { AppStateProvider } from './AppStateProvider';
import { VideoTileGridProvider } from './VideoTileGridProvider';

import { StyledLayout, StyledContent } from './Styled';



const MeetingView = ({width, height}) => {
		
	const { showNavbar, showRoster } = useNavigation();


  return (
  
    <UserActivityProvider>
	  <AppStateProvider>
		 <VideoTileGridProvider>
		  <StyledLayout 
		  	width = {width}
			height = {height}
				>
			<StyledContent 
				showNav={showNavbar} 
				showRoster={showRoster}
				>

				<VideoTileGrid 
				layout= "standard"
              className="videos"
              noRemoteVideoView={<MeetingDetails />}
			  />


			</StyledContent>
		  </StyledLayout>
		 </VideoTileGridProvider>
	  </AppStateProvider>
    </UserActivityProvider>
  );
};

export default MeetingView;
