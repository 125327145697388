const userData = {
  'users': {
},
  roles: {
    Hosts: [],
    Observers: [],
    Participants: []
    },
    
  // Facilitate reordering of the roles
  roleOrder: ['Hosts', 'Observers', 'Participants']
};

export default userData;
