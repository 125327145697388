export default {
	"content": {
		"camera": {
			"type": "camera",
			"title": "Cameras",
			"key": 0
			},
		"contentList":["camera"]
		}
	}
