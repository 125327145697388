import React from 'react';
import './StimuliCard.css';
import EditTitle from '../EditTitle/EditTitle';
import {Draggable} from 'react-beautiful-dnd' 

export default function StimuliCard({key, cardKey, title, index, layoutId, currentLayout, copyLayoutMode, thumbnail, onLayoutClick, onLayoutDeleteClick}) {
   
  function getSomeClass(){
	  if(copyLayoutMode){
		  return "copyMode"
	  } else if(currentLayout === layoutId){
		  return "selectedMode"
	  } else{
		  return "normalMode"
	  }
	  
  }
	
	
  return(
	<div id="cardHolder" className = {getSomeClass()}>

		<Draggable key={cardKey} draggableId={cardKey} index={index}>
			{(provided) => (
			
				<div id={cardKey} 
					className="card" 
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					 >  
				  		<button onClick={() => onLayoutDeleteClick(layoutId)}>x</button>
					<div className="thumbnail" onClick={() => onLayoutClick(layoutId)}>
						<img style = {{"margin-top":"10px","border":"1px solid white", "width":"75%"}} src = {thumbnail}/>
					</div>
				  <EditTitle title = {title}/>
				  
				</div>
			)}
		</Draggable>
	</div>
  )
}

