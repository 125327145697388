import React, {useState, useEffect, useContext} from 'react';
import {SocketContext} from '../socket';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import './DnDPoll.css';
import ResponseView from './ResponseView.js'

export default function DnDPoll({contentCode, room, userRole, question, answers, width, type, height}){
	
	const socket = useContext(SocketContext)
	const [response, setResponse] = useState("")
	const [view, setView] = useState("question")
	const [pollQuestion, setPollQuestion] = useState(question)
	const [pollAnswers, setPollAnswers] = useState(answers)
	const [pollType, setPollType] = useState(type)
	const [editMode, setEditMode] = useState()
	const [rView, setRView] = useState(null)

//retrieve responses 
	useEffect(() => {
			  
		socket.on("pollResponse", (data) => {
			setRView(data)
			console.log(data)
		});
		
		return () => {
			socket.off("pollResponse")
		}
	 }, [socket]);
	 
	const reorder = (list, startIndex, endIndex) => {

		const newArray = list.filter(userName => userName !== list[startIndex])
		const finalArray = [...newArray.slice(0, endIndex), list[startIndex], ...newArray.slice(endIndex)]
		return finalArray;
	};

	function handleResponseSubmitClick(){
		console.log({"contentCode": contentCode, "response": {"type": type, "question": question, "response": pollAnswers, "timeStamp": Date.now() }})
		socket.emit("pollResponse", {"contentCode": contentCode, "response": {"type": type, "question": question,"response": pollAnswers, "timeStamp": Date.now() }})	
		
	}
	
const onDragEnd = (result) => {
	console.log(result)
    const { source, destination } = result;
	const newList = reorder(pollAnswers , source.index, destination.index)
	setPollAnswers(newList)
}
	
	function handleResponseValueChange(e){
		setResponse(e.target.value)
	}
	
	function testThisThing(){
		pollAnswers.forEach(ans => console.log(ans, ans.answer, ans.id))
	}
		
	function handleQuestionChange(event){
		setPollQuestion(event.target.value);
	}
	
	function handleAnswerChange(event){
		setPollAnswers(event.target.value.split('\n'))
	}
	
	function handleTitleQuestionAnswerUpdate(){
		const newContent = {"type": pollType, "room": "perspectives", "title":"Poll", "key": contentCode, "question": pollQuestion, "answers": pollAnswers}
		socket.emit("newContent", {"contentCode": contentCode, "newContent": newContent })	
		setEditMode(!editMode)
		setResponse("")
	}
	
	function handleTypeSelect(e){
		setPollType(e.target.value)
	}
function handleViewResultsClick(){
	setView("results")
	
	if(rView===null){
		socket.emit("pollRequest", contentCode)	
	}
	
}
	
	function handleTitleQuestionAnswerCancel(){
		setPollQuestion(question)
		setPollAnswers(answers)
		setEditMode(!editMode)
	}
	
		return(
		<>
		{(userRole>1 && !editMode && view==="question") && <button style={{"float":"right", "margin":"5px"}} onClick={()=>handleViewResultsClick()}> View Results </button>}
		{(userRole>1 && view==="results") && <button style={{"float":"right", "margin":"5px"}} onClick={()=>setView("question")}> View Question </button>}
		
		<div id="poll" style={{"display":"flex", "flex-direction":"column", "align-items":"stretch", "width": width, "height": height, "margin":"auto", "border-style":"inset", "overflow":"auto", "background-color":"#BDBDBD"}}>

			{editMode &&
				<>
					 <select 
						value={pollType} 
						onChange={handleTypeSelect} 
					  >
					   <option value="MC">Multiple Choice</option>
						<option value="MA">Multiple Answer</option>
						<option value="OE">Open Ended</option>
						<option value="DD">Ranking Exercise</option>
					</select>
				</>
			}
			
			{view==="question" &&
			<>

				{editMode ? 
				<>
				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					<input onChange={handleQuestionChange} style={{"width":"90%", "background-color":"white", "padding":"2px", "font-weight":"400", "text-align":"left"}}type="text" value ={pollQuestion}/> 
				</div>
				</>
				:
				<>
				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					{question}
				</div>
				</>}
				{editMode ?
				<>
				{pollType!=="OE"&&
				<>
				<div id="poll-answers">
					<textarea id="poll-answers-text-area" placeholder="Please enter one answer per line" onChange={handleAnswerChange} name="poll-answers" rows="10" cols="50">
						{answers.join('\n')}
					</textarea>
				</div>
				
					<button className= "button" onClick={()=>handleTitleQuestionAnswerUpdate()}>Update</button>
					<button className= "button" onClick={()=>handleTitleQuestionAnswerCancel()}>Cancel</button> 
				</>}
				</>
				:
				<>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable 
						droppableId={contentCode}>
								{(provided) => (
									<div
										{...provided.droppableProps}                           
									   ref={provided.innerRef}>
				<div id="poll-answers">

					{pollAnswers.map((answer, index) =>
							<Draggable
								key={answer}
								draggableId={answer}
								index={index}>
								{(provided) => (
									<div className= "pollOption"
										
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										
										>
										{answer}
									{provided.placeholder}
									</div>
									
								)}
					
							</Draggable>
						)}
				
				</div>
				
				</div>)}
				</Droppable>
				</DragDropContext>
				</>
				}
			</>
			}
			
			{view==="results" &&
			<>	
				<div id="poll-question" style={{"margin":"10px", "color":"white", "font-weight":"bold", "background-color":"#2d343e", "padding":"5px", "border-style":"inset"}}>
					{question}
				</div>	
				
			<ResponseView
				type= {type}
				rView = {rView}
			/>
				
				
			</>
			}
		</div>
		
			{(!editMode && view==="question" ) &&
				<>{userRole===3 && <button className="button" style={{"float":"bottom"}} onClick={()=> setEditMode(!editMode)}> Edit </button>}
				<button className="button" style={{"float":"bottom"}} onClick={()=>handleResponseSubmitClick()}> Submit</button>
				</>
			}
	
	</>
	)
}