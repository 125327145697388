import React from "react";

function PrivateChatOption({name, id, onAddPrivateChatParticiantClick}) {
	
  return (
		<div 
		 id="pcoContainer"
		 style={{"display":"inline"}}
		 >
			
			<div 
				style={{"border": "1px solid black", "width":"20%", "cursor":"pointer"}}
				id="privateChatParticipant"
				onClick={() => onAddPrivateChatParticiantClick(id)}
				>
					{name}
			</div>			
		</div>
		
  );
}

export default PrivateChatOption;