import React, { useState, useContext } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {SocketContext} from '../socket';


function Homepage() {
  const room ="perspectives"
  const [id, setId] = useState(uuidv4())
  const [name, setname] = useState('')
  const socket = useContext(SocketContext)
	 
  const sendData = () => {
    if (name !== "" && room !== "") {
      socket.emit("joinRoom", {'name':name, 'room': room, 'id': id, 'role': 3});
    } else {
      alert("username is a must !");
      window.location.reload();
    }
	
	
  };

const handleNameChange=(e)=>{
	setname(e.target.value)
}

  return (
  <>
    <div className="homepage">
      <h1>Perspectives</h1>
      <input
        placeholder="Input your user name"
        value={name}
        onChange={(e) => handleNameChange(e)}
	  ></input>
      <Link to={`/${room}/${id}`}>
        <button className="b6"
		onClick={sendData}>Join</button>
      </Link>
    </div>
  </>
  );
}

export default Homepage;


