import React, {useState} from 'react';
import { FileUploader } from "react-drag-drop-files";

import './ImageDnD.css' 


const fileTypes = ["JPG", "PNG", "GIF"];

export default function ImageDnD({id, imageSrc}){
	const [file, setFile] = useState(null);
	const [isUploading, setIsUploading] = useState(false)
	const fileTypes = ["JPG", "PNG", "GIF", "PPT", "PPTX", "PDF"];
	
 	const selectFile = (event) => {
		setFile(event.target.files[0]);
		handleDnDUpload()
	};
	  
const handleDnDUpload = () => {
	 let formData = new FormData();
	 formData.append("file", file);
	 formData.append("dragKey", id)

		setIsUploading(true)
	
		
		fetch("https://docs.perspectives.icearp.net:8080/dndupload", {
			method: 'POST',
			body: formData,                                  // ***
		})
		.then((response)=>{
			if(response.status===200){
				console.log("success")
			}

		})
		.catch((error) =>{
			console.log(error)
			setIsUploading(false)
			
		})
  };
	
	return(
	<>
		{imageSrc === "" ? 
		(<FileUploader handleChange={selectFile} name="file" types={fileTypes}/>)
		:
		(<img style={{"border": "1px solid black","max-width":"50%", "max-height": "250px", "text-align":"center"}} src={imageSrc}/>)
		}
	</>
	)
}